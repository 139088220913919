.or_global {
  //common
  .section {
    &-desc {
      line-height: 1.5;
    }

    &-title {
      line-height: 1.4;
      font-size: 4rem;
    }
  }
.card-block-how-to-win-merch,.card-block-how-to-win-merch-section{
    min-height: unset !important;
    padding-top: 12px !important;
  }
  .no_ball {
    .modal-bg {
      .modal-body {
        h4 {
          font-size: 28px;
          @media (max-width: 575px) {
            font-size: 21px;
          }
          @media (max-width: 386px) {
            font-size: 16px;
          }
        }
      }
    }
  }

  .tu-language-select-side {
    font-size: 16px;
  }

  .tu-language {
    &::after {
      top: 4px;

      // @media(max-width: 500px){
      //     left: 6rem;
      // }
    }
  }

  .ticket-win,
  .card-block-how-to-win-merch {
    @media (max-width: 767.67px) {
      font-size: 3vw !important;
    }

  
  }

  @media (max-width: 550px) {
    .ticket-win {
      padding-top: 0.9rem !important;
      padding-bottom: 0.9rem !important;
    }
  }

  .form-label {
    line-height: 1.4;
  }

  .invalid-feedback,
  .error_message {
    line-height: 1.2;
    padding-top: 0rem;
    font-size: 1.5rem;
    // padding-left: 2.7rem;
  }

  .ar-cta {
    @media (max-width: 767.67px) {
      padding-right: 1.5vw;

      .btn {
        font-size: 2vw !important;
      }
    }

    @media (max-width: 500px) {
      // margin-right: 1rem;
    }
  }

  .btn {
    &-sm {
      font-size: 2rem;
    }
  }

  .tu-sidebar-list-main {
    ul {
      li {
        a {
          font-size: 14px;

          span {
            font-size: 14px;
          }
        }
      }
    }
  }

  .tu-coins-section {
    .tu-content {
      display: flex;
    }

    .btn {
      width: 100px;
    }
  }

  .tu-profile-dropdown {
    .tu-input-wrapper {
      span {
        font-size: 14px;
        padding-right: 20px !important;
      }

      input {
        font-size: 18px !important;
        padding-right: 20px !important;
      }

      li {
        font-size: 14px;
        padding-right: 20px !important;
      }
    }

    p {
      font-size: 18px !important;
      line-height: 2.4rem;
      padding-right: 20px !important;
    }
  }

  .section-title {
    font-size: 2.78vw;
    line-height: 1.25;
    margin-top: 16px !important;

    &-hotstar {
      margin-top: 0 !important;
      font-size: 9rem !important;
    }

    &-main {
      margin-top: 5rem !important;
    }

    @media (min-width: 2064.98px) {
      font-size: 42px !important;
      &-hotstar {
        font-size: 9rem !important;
      }
    }

    @media (min-width: 1200px) and (max-width: 1480px) {
      &-hotstar {
        font-size: 7.6rem !important;
        line-height: 0.82 !important;
      }
    }

    @media (min-width: 768px) and (max-width: 1199.98px) {
      &-hotstar {
        font-size: 6rem !important;
        line-height: 0.82 !important;
      }
    }

    @media (max-width: 1020px) {
      font-size: 29px !important;
      line-height: 1.3;

      &-hotstar {
        font-size: 6rem !important;
      }

      &-main {
        font-size: 26px !important;
      }
    }

    @media (max-width: 767.67px) {
      font-size: 24px !important;
      line-height: 1.3;

      &-hotstar {
        font-size: 6rem !important;
      }

      &-main {
        font-size: 23px !important;
      }
    }

    @media (max-width: 400px) {
      &-main {
        font-size: 18px !important;
      }

      &-hotstar {
        font-size: 6rem !important;
      }

      font-size: 20px !important;
    }
  }

  .banner {
    &-content-body {
      //   @media (min-width: 1199.98px) {
      //     max-width: 24vw !important;
      //   }
      max-width: 36vw;
    }

    .section {
      &-sub-title {
        line-height: 3.4rem;

        @media (max-width: 1198.98px) {
          line-height: 2rem;
        }
      }
    }
  }

  .tu-content-wrapper {
    .tu-country-select {
      span {
        font-size: 20px;
      }
    }
  }

  /* Common styles for all resolutions */
  .header {
    .tu-unique-code-wrapper {
      input {
        padding-left: 15px;
        font-size: 15px;
      }

      .btn {
        font-size: 16px;
      }

      @media (max-width: 832px) {
        margin-right: 30px;
        // min-width: 20.8rem !important;

        .btn,
        input {
          font-size: 13px !important;
        }

        .btn {
          padding: 12px 8px;
        }
      }

      @media (max-width: 440px) {
        margin-right: 10px;

        .btn,
        input {
          font-size: 10px !important;
        }
      }

      @media (max-width: 380px) {
        // min-width: 18rem !important;
        margin-right: 5px;
        margin-left: 0;

        .btn,
        input {
          font-size: 2.5vw !important;
        }
      }
    }

    .tu-wallet-link {
      font-size: 16px;

      @media (max-width: 832px) {
        font-size: 13px !important;
        margin-right: 30px;
      }

      @media (max-width: 440px) {
        font-size: 10px !important;
        margin-right: 10px;
      }

      @media (max-width: 380px) {
        margin-right: 5px;
        font-size: 2.5vw !important;
      }

      /* Default font size for larger screens */
    }

    /* Responsive font sizes and spacing using relative units */
    @media only screen and (min-width: 320px) and (max-width: 400px) {
      .tu-wallet-link {
        font-size: 12px;
        line-height: 20px;
      }

      .tu-unique-code-wrapper .btn,
      .tu-unique-code-wrapper input {
        font-size: 8px;
        line-height: 8px;
        padding-left: 6px !important;
      }
    }

    /* Responsive font sizes for different screen widths */
    @media only screen and (min-width: 320px) and (max-width: 778px) {
      .tu-wallet-link {
        font-size: 12px;
        line-height: 20px;
      }

      .tu-unique-code-wrapper .btn,
      .tu-unique-code-wrapper input {
        font-size: 12px;
        line-height: 10px;
        padding-left: 6px !important;
      }
    }

    @media (max-width: 445.98px) {
      .tu-unique-code-wrapper input {
        width: 10px !important;
        font-size: 1.3rem !important;
      }
    }

    @media (max-width: 767.98px) {
      .tu-unique-code-wrapper input {
        width: 120px !important;
      }
    }
    @media (max-width: 330px) {
      .tu-unique-code-wrapper input {
        width: 110px !important;
      }
    }
  }

  //fixes on howToWin ctas

  @media (min-width: 992px) and (max-width: 1110px) {
    .card {
      &-block {
        &-sm {
          .card {
            &-block {
              &-img-top {
                max-width: 10.5rem;
              }
            }
          }
        }
      }
    }

    .btn-sm {
      &-ticket,
      &-merch {
        margin-bottom: 1rem;
        font-size: 1.5rem;
        line-height: 1.4;
        min-height: 4.4vh;
      }

      &-merch {
        padding-top: 3.3vh;
        padding-bottom: 2rem;
      }
    }
  }

  @media (max-width: 767px) {
    .card {
      &-block {
        &-how-to-win-merch {
          min-height: 4vh;
        }
      }
    }

    .btn-sm {
      &-ticket {
        line-height: 1.2;
      }
    }
  }

  @media (max-width: 500px) {
    .card {
      &-block {
        &-how-to-win-merch {
          min-height: 4vh !important;
        }
      }
    }
  }

  //banner section
  .banner {
    @media (max-width: 500px) {
      .ar-cta {
        .tuarenternowbtn {
          font-size: 1rem;
        }
      }
    }

    @media (min-width: 1493.98px) {
      &-content {
        &-body {
          // max-width: 19vw !important;
        }
      }
    }

    @media (min-width: 1310.98px) and (max-width: 1492.98px) {
      &-content {
        &-body {
          max-width: 24vw;
        }
      }
    }

    @media (min-width: 992px) and (max-width: 1199.98px) {
      .section {
        &-title {
          &-howToWin {
            margin-top: 4rem !important;
          }
        }
      }
    }

    @media (max-width: 1025px) and (min-width: 768px) {
      &-content {
        &-img {
          margin-right: -0.5rem !important;
          margin-left: -6rem !important;
        }
      }
    }

    @media (max-width: 1199.98px) {
      .section {
        &-title {
          font-size: 3vw !important;
          line-height: 5vw;
        }
      }
    }

    @media only screen and (max-width: 767.98px) {
      &-content {
        &-body {
          max-width: 50vw;
        }
      }

      .section {
        &-title {
      //     font-size: 6.4vw !important;
      //  line-height: 7.8vw !important;
        }

        &-sub-title {
          line-height: 5vw;
          margin-left: 1.9rem;
        }
      }
      .section-title-main-home{
        font-size: 6.4vw !important;
         line-height: 7.8vw !important;
      }
      .btn {
        font-size: 4vw;
        padding-right: 4vw;
      }
    }

    @media only screen and (max-width: 575.98px) {
      &-img {
        //max-height: 28rem !important;
        object-fit: cover;
        object-position: bottom;
      }

      &-content {
        &-img {
          margin-right: -0.5rem;
          margin-left: -0.5rem;
        }
      }

      .object {
        object-position: top;
      }
    }

    @media only screen and (max-width: 325px) {
      .card-block-how-to-win-merch {
        min-height: 42px !important;
      }
    }
  }

  //middle section
  @media only screen and (max-width: 767.98px) {
    .tu-content-wrapper {
      .btn {
        font-size: 1.6rem;
      }

      // .tu-country-select {
      //     span {
      //         font-size: 20px;
      //     }
      // }
    }
  }

  //footer section
  @media only screen and (max-width: 991.98px) {
    .footer {
      p {
        font-size: 3vw;
      }
    }
  }

  //how to win section
  @media (max-width: 767px) {
    .card {
      &-block {
        .btn {
          &-primary {
            font-size: 3vw;
            line-height: 18px;
            min-height: 42px;
            width: 39vw;
            height: 42px;
         display: flex;
         padding-top: 0 !important;
         padding-bottom: 0 !important;
         justify-content:center;
         align-items: center;
            margin: 0 4px;
          }
        }
      }
    }

    .section {
      &-title {
        font-size: 9vw;

        &-hotstar {
          margin-bottom: -2vh !important;
          font-size: 4vh !important;
          margin-left: 0 !important;
        }
      }
    }

    .btn {
      font-size: 1.8rem;
    }
  }

  //meter section
  @media only screen and (max-width: 767.98px) {
    .meter-sub-heading {
      font-size: 18px;
    }
  }

  //contest section
  .contest {
    .section {
      // &-title {
      //   @media only screen and (max-width: 767.98px) {
      //     font-size: 4vw;
      //     line-height: 1.4;
      //   }
      // }
      &-title {
        @media (max-width: 430px) {
          font-size: 4.4vw !important;
          line-height: 1.4 !important;
          margin-left: 1vw !important;
        }
      }
      &-sub-title {
        @media (max-width: 1500px) {
          .text-large {
            font-size: 2.5vw;
          }
          font-size: 2.4rem;
          //   padding: 0 5rem;
        }

        @media (max-width: 1199px) {
          font-size: 2vw;

          &-heroContest {
            margin-top: 15px !important;
            line-height: 1.1;
          }
        }

        @media (max-width: 767.98px) {
          padding: 1rem 1rem;
          font-size: 3.9vw;

          &-heroContest {
            font-size: 2.5vw;
            padding: 0rem 0rem;
          }

          .text-large {
            font-size: 4.5vw;
          }
        }
        @media (max-width: 575.9px) {
          font-size: 3.6vw;

          &-heroContest {
            padding: 0rem 0rem;
            margin-top: 6px !important;
            margin-left: -1vw;
          }
        }
      }
    }
  }

  #how_to_win {
    @media only screen and (min-width: 767.98px) {
      .btn {
        font-size: 2.4rem;
      }
    }
  }

  //popup section
  .profile-success,
  .vote_now_default {
    .modal {
      &-body {
        @media only screen and (max-width: 575px) {
          h4 {
            font-size: 2.3rem !important;
            padding-top: 2rem;
          }

          h3 {
            font-size: 36px !important;
          }
        }
      }
    }
  }

  .redeem-hi {
    .modal-body {
      h4,
      h3.victory {
        font-size: 30px !important;
        line-height: 1.4;

        @media only screen and (max-width: 575px) {
          font-size: 28px !important;
        }

        @media only screen and (max-width: 430px) {
          font-size: 26px !important;
        }
      }

      @media only screen and (max-width: 430px) {
        h2 {
          font-size: 26px !important;
        }
      }
    }
  }

  /*---------------------------------------------------new css------------------------------------------------------*/
  //popup section
  .modal {
    &-title {
      font-size: 3.6rem;
      line-height: 1.4;
    }

    &-sub-title {
      font-size: 2.2rem;
      line-height: 1.4;
    }

    &-wallet {
      .caption-title {
        // font-size: 1.2rem;
        // line-height: 17px;
        font-size: 1.8rem;
        min-height: 50px;
        line-height: 1.4;

        @media (max-width: 767.98px) {
          min-height: 40px;
        }
      }

      .vote-now-btn,
      .unique-code-btn {
        font-size: 1.6rem !important;
        min-height: 62px;
        line-height: 1.4 !important;

        @media (max-width: 767.98px) {
          font-size: 1.1rem;
        }

        @media (max-width: 469.98px) {
          min-height: 84px;
          font-size: 1rem;
        }
      }
    }

    &-bg1,
    &-bg2,
    &-bg3,
    &-bg4 {
      .modal {
        &-title {
          font-size: 3.6rem;
          line-height: 1.4 !important;

          @media (max-width: 500px) {
            font-size: 2.3rem !important;
          }
        }

        &-sub-title {
          font-size: 2.2rem;
          line-height: 1.4;

          @media (max-width: 500px) {
            font-size: 2rem;
          }
        }

        &-content {
          .form-check-label {
            line-height: 1.4;
          }
        }
      }
    }

    .invalid-feedback,
    .error_message {
      padding-top: 0.8rem;
    }

    .btn {
      font-size: 2rem;
      line-height: 1.3;
    }
  }

  .collect-coins {
    .modal-body {
      h2 {
        font-size: 3rem;
        line-height: 1.4;
      }

      .victory-wrapper {
        h4 {
          font-size: 3rem;
          line-height: 1.4;
        }
      }
    }
  }

  .silver-coins {
    .match-coin-btns {
      .btn {
        font-size: 1.5rem;

        @media (max-width: 991.98px) {
          font-size: 1rem;
        }

        @media (max-width: 376px) {
          font-size: 1.2rem;
          padding: 5px;
          height: 52px;

        }
      }
    }
  }

  .display {
    &-3 {
      font-size: 1.8rem;
      line-height: 1.4;
    }
  }

  @media (max-width: 991px) {
    .collect-coins {
      .modal-body {
        .display-3,
        h6 {
          line-height: 1.5;
          font-size: 2.18vw;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .modal {
      &-title {
        font-size: 2.4rem;
      }

      &-sub-title {
        font-size: 2rem;
      }

      .btn {
        font-size: 1.2rem;
        margin: 0 10px;
      }

      &-wallet {
        .caption-title {
          font-size: 1.5rem;
        }
      }

      &-invite,
      &-claim-ticket {
        .modal-title {
          font-size: 2.4rem;
          line-height: 1.4;
        }

        .modal-sub-title {
          font-size: 2rem;
        }
      }

      &-claim-ticket {
        .modal-body {
          .modal-right-section {
            h2.claim-head {
              font-size: 2rem;
              line-height: 1.4;
            }
          }
        }

        .mandatory-text {
          font-size: 22px;
        }
      }
    }

    .collect-coins {
      .modal-body {
        .display-3,
        h6 {
          font-size: 4vw;
        }

        h2 {
          font-size: 4vw;
        }

        .victory-wrapper {
          h4 {
            font-size: 2rem;
          }
        }

        button {
          font-size: 1.6rem;
        }
      }
    }
  }

  @media (min-width: 359.98px) and (max-width: 576px) {
    .modal {
      &-wallet .caption-title {
        font-size: 1.5rem;
        line-height: 1.4;
        min-height: 63px;
      }
    }
  }

  @media (max-width: 380px) {
    .modal {
      &-wallet {
        .btn {
          font-size: 1rem;
          font-size: 11px !important;
        
        }
        .claim-btn{
          margin: 0 10px;
          padding: 5px;
        }
      }
    }

    .collect-coins {
      .modal-body {
        button {
          font-size: 1.2rem;
        }
      }
    }
  }

  @media (max-width: 359.98px) {
    .modal {
      &-wallet .caption-title {
        min-height: 105px;
      }
    }
  }

  @media (max-width: 327.9px) {
    .collect-coins {
      .modal-body {
        button {
          font-size: 1rem;
        }
      }
    }
  }

  .refer-modal {
    .modal {
      &-right-section {
        .anchor-with-underscore {
          &::after {
            width: 45% !important;

            @media (max-width: 375.9px) {
              width: 60% !important;
            }
          }

          @media (max-width: 575.9px) {
            font-size: 14px !important;
          }
        }
      }
    }
  }

  //logout btn fixes

  .logout-btn {
    @media (max-width: 855px) {
      font-size: 1.8rem !important;
      padding: 1.4vw 4vw;
    }

    @media (max-width: 1757px) {
      font-size: 2.5rem;
    }

    @media (max-width: 575px) {
      padding: 0.5rem 1.2rem !important;
      font-size: 1.7rem !important;
    }

    @media (max-width: 501px) {
      padding: 0.5rem 1.2rem !important;
    }

    @media (max-width: 338px) {
      padding: 0.5rem 1.2rem !important;
      font-size: 1.6rem !important;
    }
  }

  // AR banner
  .tu-ar-banner {
    @media (min-width: 320px) and (max-width: 767.98px) {
      width: 84vw !important;
      margin-left: auto;
      display: flex;
      transform: translate(-24%, -50%);
      flex-direction: column;
      align-items: center;
    }

    .ar-cta {
      @media (min-width: 320px) and (max-width: 767.98px) {
        margin-right: 0;
        padding-right: 0;
      }
    }
  }

  @media (max-width: 767.98px) {
    .main-title {
      font-size: 4.4vw;
    }
  }

  @media (max-width: 575.98px) {
    .title-main {
      font-size: 3.5vw !important;
      line-height: 6vw !important;
    }

    .btn-next,
    .btn-prev {
      font-size: 3vw;
      max-width: 80px;
      width: 100%;
    }
  }

  .winner-contest {
    .list {
      &-btns {
        font-size: 2rem !important;
        padding: 1.6rem 3rem;

        @media (max-width: 1200px) {
          font-size: 1.3rem !important;
          padding: 1rem 2.4rem;
        }

        @media (max-width: 435px) {
          font-size: 1rem !important;
          padding: 0.7rem 2rem;
        }
      }
    }
  }
}
