.hi_global {
  .tu-language-select-side {
    font-size: 16px;
  }

  .arena {
    .section {
      @media (max-width: 500px) {
        &-title {
          font-size: 7.5vw !important;
        }
      }
    }
  }
.card-block-how-to-win-merch{
  min-height: unset !important;
  padding: 0.9rem 1.5rem !important;
 
}
  //common
  .section {
    &-desc {
      line-height: 1.5;
      &-toofan {
        font-size: 2.2rem;

        @media only screen and (max-width: 767.98px) {
            font-size: 3vw;
        }

        @media only screen and (max-width: 600px) {
          font-size: 3.4vw;
        }

        @media only screen and (max-width: 475px) {
          font-size: 3.8vw;
        }
      }
    }

    &-title {
      line-height: 1.2;
      font-size: 3.8rem;
    }
  }

  .form-label {
    line-height: 1.4;
  }

  .invalid-feedback,
  .error_message {
    line-height: 1.4;
    padding: 0.8rem 0 0 0;
    font-size: 1.4rem;
  }

  .btn {
    &-sm {
      font-size: 2rem;
    }
  }

  .tu-wallet-link {
    @media (max-width: 830px) {
      font-size: 16px !important;
      line-height: 26px !important;
      margin-right: 35px;
    }

    @media only screen and (max-width: 410px) {
      font-size: 13px !important;
      margin-right: 10px;
    }
  }

  .tu-sidebar-list-main {
    ul {
      li {
        a {
          font-size: 16px;

          span {
            font-size: 14px;
          }
        }
      }
    }
  }

  .tu-profile-dropdown {
    .tu-input-wrapper {
      span {
        font-size: 14px;
      }

      input {
        font-size: 20px !important;
        line-height: 1;
      }
    }

    p {
      font-size: 18px !important;
      line-height: 2.4rem;
    }
  }

  .tu-content-wrapper {
    .tu-country-select {
      span {
        font-size: 20px;
      }
    }
  }

  //header section
  .header {
    .tu-unique-code-wrapper {
      input {
        padding: 2px 0 0 15px;
      }

      @media (max-width: 830px) {
        margin-right: 35px;

        .uniquecode_submit_btn {
          font-size: 14px;
        }

        input {
          font-size: 16px;
          line-height: 26px;
        }
      }

      @media (max-width: 410px) {
        margin-right: 10px;
        margin-left: 0;

        input {
          font-size: 13px;
        }

        .uniquecode_submit_btn {
          font-size: 13px;
        }
      }
    }

    &-top {
      .container-fluid {
        @media only screen and (max-width: 767.98px) {
          min-height: 34px;
        }
      }

      .tu-language-select {
        span {
          font-size: 17px;

          @media only screen and (max-width: 767.98px) {
            font-size: 12px;
          }
        }
      }
    }

    @media only screen and (max-width: 400px) {
      .tu-unique-code-wrapper {
        .btn {
          padding: 6px 5px;
        }

        // input {
        //     width: 80px;
        // }
      }
    }

    @media only screen and (max-width: 344.98px) {
      .tu-unique-code-wrapper {
        .btn,
        input {
          font-size: 12px;
        }
      }
    }
  }

  //fixes on howToWin ctas

  @media (min-width: 992px) and (max-width: 1110px) {
    .card {
      &-block {
        &-sm {
          .card {
            &-block {
              &-img-top {
                max-width: 10.5rem;
              }
            }
          }
        }
      }
    }

    .btn-sm {
      &-ticket,
      &-merch {
        margin-bottom: 1rem;
        font-size: 1.5rem;
        line-height: 1;
        min-height: 3.6vh;
      }

      &-merch {
        padding-top: 1.3vh;
        padding-bottom: 1rem;
      }
    }
  }

  @media (max-width: 767px) {
    .card {
      &-block {
        &-how-to-win-merch {
          min-height: 4vh;
        }
      }
    }

    .btn-sm {
      &-ticket {
        line-height: 1.2;
      }
    }
  }

  @media (max-width: 500px) {
    .card {
      &-block {
        &-how-to-win-merch {
        min-height: 52px !important;
        }
      }
    }
  }

  //banner section
  .banner {
    .section {
    
      &-sub-title {
        .text-large {
          font-size: 3.5rem;
        }
        font-size: 2.8rem;
        padding: 0 4rem;
      }
    }

    @media (max-width: 500px) {
      .ar-cta {
        .tuarenternowbtn {
          font-size: 1.5rem;
        }
      }
    }

    @media (min-width: 2300.98px) {
      .section {
        &-title {
          font-size: 2.6vw !important;
        }
      }
    }

    @media (min-width: 1493.98px) {
      &-content {
        &-body {
          // max-width: 19vw;
          max-width: 36vw;
        }
      }

      .section {
        &-title {
          font-size: 3vw;
        }
      }
    }

    @media (min-width: 1310.98px) and (max-width: 1492.98px) {
      &-content {
        &-body {
          max-width: 36vw;
        }
      }
    }

    @media (max-width: 1199.98px) {
      .section {
        &-title {
          &-howToWin {
            margin-top: 5rem;
          }

          font-size: 3vw;
          line-height: 4vw;
        }

        &-sub-title {
          .text-large {
            font-size: 2vw;
          }
          font-size: 2vw;
          padding: 0 1rem;
        }
      }
    }

    @media (max-width: 998px) {
      .section {
        &-title {
          font-size: 3vw;
          line-height: 4vw;
        }

        &-sub-title {
          padding: 0 6rem;

          .text-large {
            font-size: 3vw;
          }

          font-size: 2vw;
        }
      }
    }

    @media only screen and (max-width: 767px) {
      // &-content {
      //     &-body {
      //         max-width: 37vw;
      //     }
      // }

      .section {
        &-title {
          font-size: 4vw;
          line-height: 4vw;
        }

        &-sub-title {
          padding: 0 4rem;

          .text-large {
            font-size: 4vw;
          }

          font-size: 3vw;
        }
      }
    }
    @media only screen and (max-width: 575.98px) {
      &-img {
        max-height: 28rem;
        object-fit: cover;
        object-position: bottom;
      }

      .object {
        object-position: top;
      }

      .section {
        &-title {
          font-size: 6.5vw;
          line-height: 8vw;
        }

        &-sub-title {
          padding: 0 2.65rem;

          .text-large {
            font-size: 4vw;
          }

          font-size: 3vw;
        }
      }
    }

    @media only screen and (max-width: 325px) {
      .card-block-how-to-win-merch {
        min-height: 52px;
      }
    }
  }

  //middle section
  @media only screen and (max-width: 767.98px) {
    .claim-btn{
      margin: 0 10px;
    }
    .tu-content-wrapper {
      .btn {
        font-size: 1.6rem;
      }

     
    }
  }

  //footer section
  @media only screen and (max-width: 991.98px) {
    .footer {
      p {
        font-size: 3vw;
      }
    }
  }

  //how to win section
  @media (max-width: 500px) {
    .card {
      &-block {
        .btn {
          &-primary {
            font-size: 3.9vw;
            line-height: 18px;
            display: flex;
            margin: 0 4px;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    .section {
      &-title {
        font-size: 9vw;
      }
    }

    .btn {
      font-size: 1.8rem;
    }
  }

  //logout btn fixes
  .logout-btn {
    @media (max-width: 855px) {
      font-size: 1.8rem !important;
    }

    @media (max-width: 1757px) {
      font-size: 2.5rem;
    }

    @media (max-width: 575px) {
      padding: 0.5rem 1.2rem !important;
      font-size: 1.7rem !important;
    }

    @media (max-width: 501px) {
      padding: 0.5rem 1.2rem !important;
    }

    @media (max-width: 338px) {
      padding: 0.5rem 1.2rem !important;
      font-size: 1.6rem !important;
    }
  }

  //meter section
  @media only screen and (max-width: 767.98px) {
    .meter-sub-heading {
      font-size: 18px;
    }
  }

  //contest section
  .contest {
    .section {
      &-title {
        @media only screen and (max-width: 767.98px) {
          font-size: 5vw;
          line-height: 6vw;
        }
      }
    }
  }

  #how_to_win {
    @media only screen and (min-width: 767.98px) {
      .btn {
        font-size: 2.4rem;
       padding-top: 1.4rem !important;
       height: 56px !important;
       min-height: unset;
      }
    }
  }

  //popup section
  .profile-success,
  .vote_now_default {
    .modal {
      &-body {
        @media only screen and (max-width: 575px) {
          h4 {
            font-size: 2.3rem !important;
            padding-top: 2rem;
          }

          h3 {
            font-size: 36px !important;
          }
        }
      }
    }
  }

  .redeem-hi {
    .modal-body {
      h4,
      h3.victory {
        font-size: 30px !important;
        line-height: 1.4;

        @media only screen and (max-width: 575px) {
          font-size: 28px !important;
        }

        @media only screen and (max-width: 430px) {
          font-size: 26px !important;
        }
      }

      @media only screen and (max-width: 430px) {
        h2 {
          font-size: 26px !important;
        }
      }
    }
  }

  .modal-claim-ticket {
    select.form-control {
      line-height: 2;

      @media (max-width: 425.98px) {
        height: 55px;
      }
    }
  }

  /*---------------------------------------------------new css------------------------------------------------------*/
  //popup section
  .modal {
    &-title {
      font-size: 3.6rem;
      line-height: 1.4;
    }

    &-sub-title {
      font-size: 2.2rem;
      line-height: 1.4;
    }

    &-wallet .caption-title {
      font-size: 1.8rem;
    }

    &-bg1,
    &-bg2,
    &-bg3,
    &-bg4 {
      .modal {
        &-title {
          font-size: 3.6rem;
          line-height: 1.4 !important;

          @media (max-width: 500px) {
            font-size: 2.3rem !important;
          }
        }

        &-sub-title {
          font-size: 2.2rem;
          line-height: 1.4;

          @media (max-width: 500px) {
            font-size: 2rem;
          }
        }

        &-content {
          .form-check-label {
            line-height: 1.4;
          }
        }
      }
    }

    .btn {
      font-size: 2rem;
      line-height: 1.3;
    }
  }

  .collect-coins {
    .modal-body {
      h2 {
        font-size: 3.4rem;
        line-height: 1.4;
      }

      .victory-wrapper {
        h4 {
          font-size: 3rem;
          line-height: 1.4;
        }
      }
    }
  }

  .display {
    &-3 {
      font-size: 1.6rem;
      line-height: 1.4;
    }
  }

  @media (max-width: 991px) {
    .collect-coins {
      .modal-body {
        .display-3,
        h6 {
          line-height: 1.5;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .modal {
      &-title {
        font-size: 2.4rem;
      }

      &-sub-title {
        font-size: 2rem;
      }

      .btn {
        font-size: 1.6rem;
      }
.contest-btn-hi{
  padding: 2.2rem 0.5rem !important;
  font-size: 3vw;
}
      &-wallet {
        .caption-title {
          font-size: 1.5rem;
        }
      }

      &-invite,
      &-claim-ticket {
        .modal-title {
          font-size: 2.4rem;
          line-height: 1.4;
        }

        .modal-sub-title {
          font-size: 2rem;
        }
      }

      &-claim-ticket {
        .modal-body {
          .modal-right-section {
            h2.claim-head {
              font-size: 2rem;
              line-height: 1.4;
            }
          }
        }

        .mandatory-text {
          font-size: 22px;
        }

      }
    }

    .collect-coins {
      .modal-body {
        .display-3,
        h6 {
          font-size: 1rem;
        }

        h2 {
          font-size: 2rem;
        }

        .victory-wrapper {
          h4 {
            font-size: 2rem;
          }
        }

        button {
          font-size: 1.6rem;
        }
      }
    }
  }

  @media (max-width: 576px) {
    .modal {
      &-wallet .caption-title {
        font-size: 1.6rem;
        max-width: 170px;
        margin: 0 auto;
      }
    }
  }

  @media (max-width: 380px) {
    .modal {
      &-wallet {
        .btn {
          font-size: 1rem;
        }
      }
    }

    .collect-coins {
      .modal-body {
        button {
          font-size: 1.2rem;
        }
      }
    }
  }

  @media (max-width: 359.9px) {
    .modal {
      &-wallet .caption-title {
        font-size: 1.3rem;
      }
    }
  }

  @media (max-width: 327.9px) {
    .collect-coins {
      .modal-body {
        button {
          font-size: 1rem;
        }
      }
    }
  }

  // AR banner
  .tu-ar-banner {
    @media (min-width: 320px) and (max-width: 767.98px) {
      width: 84vw !important;
      margin-left: auto;
      display: flex;
      transform: translate(-24%, -50%);
      flex-direction: column;
      align-items: center;
    }

    @media (min-width: 320px) and (max-width: 767.98px) {
      .ar-cta {
        margin-right: 0;
        padding-right: 0;

        a {
          font-size: 12px;
          padding: 7px;
        }
      }
    }

    .ar-title {
      @media (min-width: 320px) and (max-width: 767.98px) {
        font-size: 5.5vw !important;
      }
    }
    .tu-language-select-side {
      font-size: 1.6rem;
    }
  }

  .winner-contest {
    .list {
      &-btns {
        font-size: 2rem !important;
        padding: 1.6rem 3rem;

        @media (max-width: 1200px) {
          font-size: 1.3rem !important;
          padding: 1rem 2.4rem;
        }

        @media (max-width: 435px) {
          font-size: 1rem !important;
          padding: 0.7rem 2rem;
        }
      }
    }
  }

  .no_ball {
    .modal-bg {
      .modal-body {
        h4 {
          font-size: 34px;
          @media (max-width: 575px) {
            font-size: 24px;
          }
          @media (max-width: 380px) {
            font-size: 19px;
          }
        }
      }
    }
  }
}
