.card {
  &-block {
    background-color: transparent;
    border: none;
    text-align: center;
    &-img-top {
      max-width: 18.6rem;
      margin-bottom: 2.2vw;
      position: relative;
      z-index: 2;
      width: 100%;
   height: 18.6rem;
    }
    &-how-to-win-merch{
      min-height: 66px;
      padding-top: 2rem;
      padding-left: 2rem !important;
      padding-right: 2rem !important;
    }
    &-how-to-win-merch-section{
      min-height: 88px;
      padding-top: 2.8rem;
      padding-left: 2rem !important;
      padding-right: 2rem !important;
    }
    &-sm {
      .card {
        &-block {
          &-img-top {
            max-width: 14rem;
            height: 14rem;
          }
        }
      }
    }
  }
}
@media (max-width: 1199.98px) {
  .card {
    &-block {
      &-sm {
        .btn {
          &-primary {
            font-size: 2.4vw;
          }
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  .card {
    &-block {
      &-sm {
        .card {
          &-block {
            &-img-top {
              max-width: 17vw;
            }
          }
        }
        
      }
    }
  }
}

@media (max-width: 767.67px) {
  .card {
    &-block {
      .btn {
        &-primary {
          font-size: 4vw;
          padding: 1vw 2.2vw;
          line-height: 1.1;
        }
      }
      &-img-top {
        width: 55%;
        margin-bottom: 1.5rem;
      }
      &-how-to-win-merch{
        min-height: 7.2vh;
        padding: 1.9rem 1.8rem !important;
      }
      &-how-to-win-merch-section{
        min-height: 63px;
        padding: 2.2rem 0.5rem !important;
      }
      &-sm {
        .card {
          &-block {
            &-img-top {
              max-width: 18vw !important;
              height:18vw;

            }
          }
        }
      }
    }
  }
}

@media (max-width: 630px){
  .card{
    &-block{
      &-how-to-win-merch-section{
        min-height: 64px;
        padding: 2rem 0.5rem !important;
      }
    }
  }
}

@media (max-width : 550px){
  .card{
    &-block{
      &-img-top{
      width: 28vw;
      height: 28vw;
      }
      &-how-to-win-merch{
        min-height: 57px;
        padding-top: 1.6rem !important;
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
      }
      &-how-to-win-merch-section{
        min-height: 58px;
        padding: 1.8rem 0.5rem !important;
      }
    }
  }
}

@media (max-width: 530px){
  .card{
    &-block{
      &-how-to-win-text{
        font-size: 3vw !important;
      }
    }
  }
}

@media (max-width : 500px){
  .card{
    &-block{
      &-how-to-win-merch{
        padding-left: 1.2rem !important;
        padding-right: 1.2rem !important;
        padding-top: 1.5rem !important;
       
        min-height: 44px;
      }
      .btn {
        &-primary {
          line-height: 1.1;
        }
      }
      &-how-to-win-merch-section{
        min-height: 51px;
        padding: 1.6rem 0.1rem !important;
      }
    }
  }
}

@media (max-width : 430px){
  .card{
    &-block{
      &-how-to-win-merch-section{
        min-height: 38px;
        padding: 1.2rem 0.1rem !important;
      }
    }
  }
}