.winner-contest {
  background: url('~/public/assets/images/bgs/bg-3.png');
  background-size: cover;

  .list {
    padding-inline-start: 0px;
    &-wrapper {
      border: 1.5px solid $light;
      padding: 2vw;
      border-radius: 3rem;
    }
    &-contestent {
      max-height: 140vh;
    }
    &-item {
      display: flex;
      justify-content: space-around;
      line-height: 3vw;
      border-radius: 1vw;
      padding: 0.5vw;
      margin-bottom: 1.8vw;
      h6 {
        margin: 0;
      }
      &:hover{
        border-bottom-color: transparent;
      }
    }
    &-head {
      background-color: $primary;
    }
    &-avatar-wrapper {
      position: relative;
    }
    &-avatar {
      max-width: 5rem;
      border-radius: 50%;
      position: absolute;
      left: 20%;
      top: 50%;
      transform: translate(-50%, -50%);
      border: 3px solid $light;
    }
    &-anchor {
      font-weight: 400;
      font-size: 24px;
      line-height: 27px;
      text-decoration: underline;
      text-align: center;
    }
    &-heading{
      max-width: 34vw;
      width: 100%;
      padding : 0 2rem;
    }
    &-special-heading{
      max-width: 10vw;
      width: 100%;
      padding : 0 1rem;
    }

    &-btns{

      font-size: 3rem !important;
      padding: 1.4rem 3rem;
      
    @media (max-width:1200px) {

      font-size: 2rem !important;
      padding: 1.2rem 2.4rem;
      
    }
      
    @media (max-width:435px) {

      font-size: 1.4rem !important;
      padding: 0.7rem 2rem;
      
    }
    
    }
  }

  @media(max-width:1300px){
    .list{
      &-avatar{
        max-width: 4.5rem;
        left: 23%;
      }
    }
  }
  @media(max-width:1100px){
    .list{
      &-avatar{
        // max-width: 4rem;
        left: 30%;
      }
    }
  }
  @media(max-width:950px){
    .list{
      &-avatar{
        max-width: 4rem;
        left: 36%;
      }
    }
  }

  @media (max-width: 767.67px) {
    background: url('~/public/assets/images/bgs/bg-4.png');
    background-size: cover;

    .winners-div {
      min-width: 99vw;
    }
    .list {
      &-wrapper {
        padding: 3vw 3vw;
      }
      &-item {
        margin-bottom: 4vw;
        border-radius: 2vw;
      }
      &-avatar {
        max-width: 6vw;
        left:30%;
      }
      &-div{
        max-width: 16vw;
      }
      &-heading{
        max-width: 34vw;
        padding : 0 2rem;
      }
      &-special-heading{
        max-width: 15vw;
        padding : 0 1rem;
      }
    }
  }
  @media (max-width:500px){
    .list{
      &-avatar{
        max-width: 8vw;
        left: 40%;
      }
    }
  }

  @media(max-width:430px){
    .list{
      &-item{
        padding: 4px 4px;
        h6{
          font-size:12px;
        }
      }
      &-wrapper {
        padding: 3vw 2vw;
      }
      &-div{
        max-width: 18vw;
      }
    }
  }
}
