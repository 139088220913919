.tl_global {
  //common
  .invalid-feedback,
  .error_message {
    padding-top: 8px;
    padding-bottom: 1rem;
  }

  .no_ball {
    .modal-bg {
      .modal-body {
        h4 {
          font-size: 28px;
          @media (max-width: 575px) {
            font-size: 21px;
          }
          @media (max-width: 391px) {
            font-size: 15px;
          }
        }
      }
    }
  }

  .modal-bg3 .modal-content .modal-body .modal-sub-title {
    margin-top: 25px;
    padding-right: 5rem;
    line-height: 28px;
  }

  .btn-sm {
    font-size: 1.4rem;
    border-radius: 0.6rem;
    @media (max-width: 767.67px) {
      font-size: 1rem;
    }
  }



  .redeem-merch-para {
    @media (max-width: 767.67px) {
      font-size: 2rem;
    }
    @media (max-width: 575.9px) {
      font-size: 1.5rem;
    }
  }

  .anchor-with-underscore {
    font-size: 18px !important;
    &::after {
      top: 94% !important;
      width: 47% !important;
    }

    @media (max-width: 500px) and (min-width: 489px) {
      &::after {
        width: 50% !important;
      }
    }

    @media (max-width: 488px) and (min-width: 475px) {
      &::after {
        width: 52% !important;
      }
    }

    @media (max-width: 430px) and (min-width: 381px) {
      &::after {
        width: 60% !important;
      }
    }

    @media (max-width: 380px) and (min-width: 340px) {
      &::after {
        width: 73% !important;
      }
    }

    @media (max-width: 339px) {
      &::after {
        width: 81% !important;
      }
    }
  }

  .modal-right-section {
    h6 {
      line-height: 1.5;
    }
    .btn {
      font-size: 2.5rem;
      @media (max-width: 575.9px) {
        font-size: 1.5rem;
      }
      @media (max-width: 350px) {
        font-size: 1.2rem;
      }
    }
  }

  .section {
    &-title {

      &-main-heroContest {
        margin-left: -11rem;
      }

      font-size: 4.5rem;
      @media (max-width: 1199.98px) {
        font-size: 4vw;
       
        &-main-heroContest {
          margin-left: 8rem !important;
          padding: 0rem 1rem;
          line-height: 1;
          font-size: 4rem;
        }
      }
      @media (max-width: 767.98px) {
        font-size: 5vw;
      }
      @media (max-width: 575.9px) {
        font-size: 5.5vw;
      }
      @media (max-width: 430px) {
        font-size: 6vw;
      }
    }
    &-desc {
      @media (max-width: 575.9px) {
        font-size: 3.5vw;
        padding: 0 4rem;
      }

      &-toofan {
        line-height: 1.5;
        font-size: 2.2rem;

        @media only screen and (max-width: 767.98px) {
            font-size: 3vw;
        }

        @media only screen and (max-width: 600px) {
          font-size: 3.4vw;
        }

        @media only screen and (max-width: 475px) {
          font-size: 3.8vw;
        }
      }
    
    }
    &-sub-title {
      font-size: 3rem;
      @media (max-width: 1200px) {
        font-size: 2.5rem;
      }
      @media (max-width: 575.9px) {
        font-size: 4.5vw;
      }
    }
  }

  .btn {
    line-height: 1.4;
  }
  .tu-language-select-side {
    font-size: 16px;
    @media (max-width: 575.98px) {
      font-size: 14px;
    }
  }
  .tu-language {
    &::after {
      top: 4px;
    }
  }

  .form {
    &-label {
      line-height: 1.4;
    }
  }

  //sidebar section
  .tu-sidebar-list-main {
    li {
      a {
        font-size: 14px;

        @media only screen and (max-width: 345px) {
          font-size: 12px;
        }

        span {
          font-size: 10px !important;
        }
      }
    }
  }

  .logoutbtn {
    font-size: 2rem !important;
  }

  //profile section
  .tu-profile-dropdown {
    .tu-input-wrapper {
      span {
        font-size: 12px;
      }

      .tu-user-input {
        font-size: 21px !important;
        line-height: 1 !important;
      }
    }

    .small {
      font-size: 1.5rem;
      line-height: 1.5;
    }

    .tu-coins-section,
    .tu-referrals-section {
      p {
        font-size: 14px;
      }

      .btn {
        font-size: 9px;
      }
    }
  }

  //header section
  .header {
    .tu-unique-code-wrapper {
      .btn {
        font-size: 14px;
      }
      input {
        font-size: 18px;
      }

      // @media (min-width: 993px) {
      //     .btn{
      //         font-size: 11px;
      //     }
      //     input{
      //         font-size: 12px;
      //     }
      // }

      @media (max-width: 992px) {
        margin-right: 30px;
        .btn {
          font-size: 9px;
        }
        input {
          font-size: 12px;
        }
      }
      @media (max-width: 767.67px) {
        margin-right: 10px;
        .btn {
          font-size: 13px !important;
        }

        input {
          font-size: 13px !important;
          padding-left: 6px;
        }
      }
      @media (max-width: 500px) {
        .btn {
          font-size: 13px;
        }

        input {
          font-size: 13px !important;
          padding-left: 6px;
        }
      }
      @media (max-width: 415px) {
        margin-left: 0;
        margin-right: 5px;
        .btn,
        inpuy {
          font-size: 10px !important;
        }
      }
    }

    .tu-wallet-link {
      @media (max-width: 992px) {
        margin-right: 30px;
      }
      @media (min-width: 767.98px) {
        font-size: 13px !important;
      }
      @media (max-width: 767.67px) {
        font-size: 16px !important;
      }
      @media (max-width: 400px) {
        font-size: 13px !important;
        margin-right: 10px;
      }
      @media (max-width: 350px) {
        font-size: 3.5vw !important;
      }
    }

    @media only screen and (max-width: 370px) {
      .tu-wallet-link {
        font-size: 12px;
        line-height: 16px;
      }

      .tu-unique-code-wrapper {
        .btn {
          font-size: 6px !important;
        }
        input {
          font-size: 10px !important;
          padding-left: 6px;
        }
      }
    }
  }

  //fixes on howToWin ctas

  @media (min-width: 992px) and (max-width: 1110px) {
    .card {
      &-block {
        &-sm {
          .card {
            &-block {
              &-img-top {
                max-width: 10.5rem;
              }
            }
          }
        }
      }
    }

    .btn-sm {
      &-ticket,
      &-merch {
        margin-bottom: 1rem;
        font-size: 1.5rem;
        line-height: 1;
        min-height: 3.6vh;
      }

      &-merch {
        padding-top: 1.3vh;
        padding-bottom: 1rem;
      }
    }
  }

  @media (max-width: 767px) {
    .card {
      &-block {
        &-how-to-win-merch {
          min-height: 4vh;
        }
      }
    }

    .btn-sm {
      &-ticket {
        line-height: 1.2;
      }
    }
  }

  @media (max-width: 500px) {
    .card {
      &-block {
        &-how-to-win-merch {
          min-height: 4vh !important;
        }
      }
    }
  }

  //banner section
  .banner {
    &-content {
      @media (max-width: 430px) {
        margin-top: 10px;
      }
      &-body {
        @media (min-width: 767.67px) {
          max-width: 41vw;
        }
      }
    }
    .section {
      &-title {
        line-height: 1.2;
        font-size:2.67vw;

        @media (max-width: 929px) {
          font-size: 2.4rem;
        }

        @media (max-width: 767.98px) {
          padding: 0 1rem;
          font-size: 3.9vw;

          &-main-heroContest {
          margin-left: -10px !important;
          }
        }
        @media (max-width: 575.9px) {
          font-size: 4.6vw;

          &-main-heroContest {
            margin-left: -10px !important;
            margin-top: 4rem !important;
            font-size: 5vw;
          }
        }
      }

      &-sub-title {
        @media (max-width: 1500px) {
          .text-large {
            font-size: 2.5vw;
          }
          font-size: 2.4rem;
          //   padding: 0 5rem;
        }

        @media (max-width: 1199px) {
          font-size: 2vw;

          &-heroContest {
            margin-top: 15px !important;
            line-height: 1.1;
          }
        }

        @media (max-width: 767.98px) {
          padding: 1rem 1rem;
          font-size: 3.9vw;

          &-heroContest {
            font-size: 2.5vw;
            padding: 0rem 0rem;
          }

          .text-large {
            font-size: 4.5vw;
          }
        }
        @media (max-width: 575.9px) {
          font-size: 3.6vw;

          &-heroContest {

          padding: 0rem 0rem;
          margin-top: 9px !important;
          }
        }
      }
    }

    .card-block-how-to-win-merch,
    .ticket-win {
      min-height: 66px;
      padding-top: 1rem;
      padding-left: 2rem;
      padding-right: 2rem;
      max-width: 30rem;
      line-height: 1.4;
      font-size: 2rem;
    }
    .card-block-how-to-win-merch {
      padding-top: 2.1rem;
      padding-bottom: 2.5rem;
      @media (max-width: 767.98px) {
        max-width: 20rem;
        padding: 1.5rem 4rem !important;
      }
      @media (max-width: 575.98px) {
        max-width: 20rem;
        padding: 1rem 2rem !important;
        font-size: 2.2vw;
        height: 6vh !important;
        min-height: unset;
        margin: 0 4px;
        display: flex;
        justify-content: center;
        align-items: center;

      }
   
    }
    .how-to-win-text {
      @media (max-width: 1200px) {
        margin-top: 4rem;
      }
      @media (max-width: 575.9px) {
        margin-top: 4rem;
      }
    }
    .ticket-win {
      @media (max-width: 767.98px) {
        max-width: 30rem;
        padding: 1.5rem 4rem !important;
      }
      @media (max-width: 575.98px) {
        max-width: 20rem;
        padding: 1rem 2rem !important;
        font-size: 2.2vw !important;
        min-height: unset;
        height: 6vh;
        display: flex;
        justify-content: center;
        align-items: center;

      }
     
    }

    .ar-cta {
      @media (max-width: 767.67px) {
        padding-right: 2.5vw;
      }
    }

    @media (max-width: 500px) {
      .banner-collect {
        padding-right: 2px !important;
      }
      .ar-cta {
        padding: 0;
        margin-right: 0;
        .tuarenternowbtn {
          line-height: 1.4;
          font-size: 1rem;
          max-width: 40%;
          // padding: 0;
        }
      }
    }
  }

  //middle section
  .tu-content-wrapper {
    .section {
      &-title {
        line-height: 1.5;
        font-size: 26px;
      }

      &-desc {
        line-height: 1.4;
        font-size: 2rem;

        @media (max-width: 380.98px) {
          font-size: 3.4vw;
          line-height: 1.4;
        }
      }
    }

    .btn {
      line-height: 1.4;
      font-size: 1.8rem;
    }

    @media (max-width: 767px) {
      .btn {
        font-size: 18px;
      }

    }

    @media (max-width: 575.98px) {
      .btn {
        font-size: 12px;
      }
    }

    .tu-country-select {
      span {
        font-size: 20px;
      }
    }
  }

  //arena section
  .bg-pattern-arena {
    .section {
      &-title {
        font-size: 5rem;
        line-height: 1.5;

        @media (max-width: 991.98px) {
          font-size: 3rem;
        }
      }

      &-desc {
        @media (max-width: 991.98px) {
          font-size: 1.5rem;
          line-height: 1.2;
        }
      }
    }

    @media (max-width: 991.98px) {
      .tuarenternowbtn {
        font-size: 1.5rem;
      }
    }
  }

  //footer section
  .footer {
    p {
      font-size: 1.5rem;

      @media (max-width: 430px) {
        font-size: 3vw;
        line-height: 3vw;
        margin: 1.65vw 0;
      }
    }
  }

  //belivometer
  .meter-sub-heading {
    line-height: 1.2;
  }

  //contest
  .contest {
    .section {
      &-title {
        @media (max-width: 430px) {
          font-size: 3.94vw;
          line-height: 1.4;
        }
      }
    }
  }

  .winner-contest {
    .section {
      &-desc {
        line-height: 1.8;
      }
    }
    .list {
      &-btns {
        font-size: 2rem !important;
        padding: 1.2rem 2rem;

        @media (max-width: 1200px) {
          font-size: 1.3rem !important;
          padding: 0.8rem 1.7rem;
        }

        @media (max-width: 435px) {
          font-size: 1rem !important;
          padding: 0.7rem 1.3rem;
        }
      }
    }
  }

  .contest-ticket {
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .contest-btn {

       
        height: 52px;
        display: flex;
        min-height: unset;
        align-items: center;
    justify-content: center;
    line-height: 1.5;
    font-size: 2.5rem;
   margin: 0 4px;
   min-width: 23vw !important;
  }

  .contest-ticket {
    .btn {
      padding: 0.8rem 2rem !important;
    }
  }
  .card-block-how-to-win-merch-section {
    padding: 2rem 2rem !important;
  }
  @media (max-width: 991px) {
    .contest-btn {
      font-size: 2.5vw;
      min-width: 25rem;
    }
    .card-block-how-to-win-merch-section {
      padding: 2.5rem 2rem 1rem !important;
    }
    .contest-ticket {
      .btn {
        padding: 1.5rem 2rem !important;
      }
    }
  }
  @media (max-width: 767.67px) {
    .contest-ticket {
      .btn {
        padding: 0.8rem 2rem !important;
      }
    }
    .card-block-how-to-win-merch-section {
      padding: 2rem !important;
    }
  }

  @media (max-width: 440px) {
    .contest-btn {
      font-size: 3vw;
      min-width: 14rem;
      height: 50px !important;

    }
  }
  @media (max-width: 375px) {
    .contest-btn {
      font-size: 3vw;
      min-width: 14rem;
    }
    .contest-ticket {
      .btn {
        padding: 1.3rem 2rem !important;
      }
    }
  }

  @media (max-width: 338px) {
    .contest-btn {
      margin-left: -0.5rem;
      font-size: 3vw;
      min-width: 14rem;
    }
    .contest-ticket {
      .btn {
        padding: 1.3rem 2rem !important;
      }
    }
  }

  //modals
  .modal {
    &-title {
      font-size: 3.6rem;
      line-height: 1.4;

      @media (max-width: 767.67px) {
        font-size: 2.6rem;
      }
      @media (max-width: 575.9) {
        font-size: 2rem;
      }
    }

    &-sub-title {
      font-size: 2.2rem;
      line-height: 1.5;
      @media (max-width: 767.67px) {
        font-size: 2rem;
      }
    }

    &-wallet .caption-title {
      font-size: 1.8rem;
      min-height: 84px;
      line-height: 1.4;

      @media (max-width: 530.98px) {
        font-size: 1.2rem;
        min-height: 54px;
      }

      @media (max-width: 384.98px) {
        font-size: 1rem;
      }

      @media (max-width: 325.98px) {
        min-height: 74px;
      }
    }

    &-wallet {
      .btn {
        line-height: 1.4;
        font-size: 10px;
        padding: 4px;
        
      }
.claim-btn{
margin: 0 10px;
}
      .vote-now-btn,
      .unique-code-btn {
        font-size: 1.6rem;
        min-height: 62px;

        @media (max-width: 767.98px) {
          font-size: 1.1rem;
          min-height: 52px;
        }

        @media (max-width: 438.98px) {
          min-height: 62px;
          font-size: 1rem;
        }
      }
    }

    &.refer-modal {
      .modal-content {
        top: 10rem;
      }
    }

    &-bg1,
    &-bg2,
    &-bg3,
    &-bg4 {
      .modal {
        &-title {
          font-size: 3.6rem;
          line-height: 1.5 !important;

          @media (max-width: 500px) {
            font-size: 5.5vw !important;
          }
        }

        &-sub-title {
          font-size: 2rem;
          line-height: 1.4;

          @media (max-width: 500px) {
            font-size: 1.6rem;
          }
        }

        &-content {
          .form-check-label {
            line-height: 1.4;
          }
        }

        .btn {
          font-size: 2rem;
          line-height: 1.3;
          padding: 1rem;
        }
      }
    }
  }

  .profile-success {
    h2,
    h4 {
      line-height: 1.4;
    }
  }

  @media only screen and (max-width: 575.98px) {
    .vote_now_default,
    .profile-success {
      .modal {
        &-body {
          h3 {
            font-size: 30px;
          }

          h4 {
            font-size: 20px;
          }
        }
      }
    }
  }

  .modal-invite,
  .refer-modal {
    .modal {
      &-title {
        max-width: 100% !important;
        line-height: 1.5 !important;

        @media only screen and (max-width: 575.98px) {
          font-size: 2.6rem;
        }
      }
    }
  }

  .redeem-success {
    .modal {
      &-body {
        h3.victory {
          padding-bottom: 10px;
          font-size: 34px !important;

          @media only screen and (max-width: 575.98px) {
            font-size: 2.2rem !important;
          }
        }

        h4.victories {
          font-size: 24px;

          @media only screen and (max-width: 575.98px) {
            font-size: 1.2rem !important;
          }
        }

        .btn {
          font-size: 22px !important;

          @media only screen and (max-width: 575.98px) {
            font-size: 1.4rem !important;
          }
        }
      }
    }
  }

  .win-ticket {
    .modal {
      &-body {
        .display-2 {
          padding: 10px !important;
          line-height: 1.4 !important;
        }

        .display-3 {
          font-size: 28px;

        }
      }
    }
  }

  .collect-coins {
    .modal-body {
      @media (max-width:767.98px) {
        .bottom-txt{
          font-size: 3.4vw;
          line-height: 5vw;
        }
      }
      @media only screen and (max-width: 380.98px) {
        .display-3 {
          font-size: 10px;
          line-height: 1.5;

        }
      }
    }
  }

  //logout btn fixes

  .logout-btn {
    @media (max-width: 855px) {
      font-size: 1.8rem !important;
    }

    @media (max-width: 1757px) {
      font-size: 2.5rem;
    }

    @media (max-width: 575px) {
      padding: 0.5rem 1.2rem !important;
      font-size: 1.7rem !important;
    }

    @media (max-width: 501px) {
      padding: 0.5rem 1.2rem !important;
    }

    @media (max-width: 338px) {
      padding: 0.5rem 1.2rem !important;
      font-size: 1.6rem !important;
    }
  }

  .form {
    &-control {
      @media only screen and (max-width: 425px) {
        height: 42px;
        font-size: 1.4rem;
      }
    }
    // AR banner
    .tu-ar-banner {
      @media (min-width: 320px) and (max-width: 767.98px) {
        width: 84vw !important;
        margin-left: auto;
        display: flex;
        transform: translate(-24%, -50%);
        flex-direction: column;
        align-items: center;
      }
      .ar-cta {
        @media (min-width: 320px) and (max-width: 767.98px) {
          margin-right: 0;
          padding-right: 0;
        }
      }
    }
    @media (max-width: 470.98px) {
      .winner-contest {
        .list-item {
          h6 {
            font-size: 9px !important;
          }
        }
      }
    }
    @media (max-width: 480px) {
      .title-main {
        font-size: 3.6vw !important;
      }
    }
  }

  .tu-playlist-wrapper {
    &-videoTitle {
      font-size: 1.5rem;

      @media only screen and (max-width: 967px) {
          font-size: 13.8px;
      }
      
      @media only screen and (max-width: 767px) {
          font-size: 15px;
      }
      
      @media only screen and (max-width: 575px) {
          font-size: 12.7px;
      }
      
      @media only screen and (max-width: 357px) {
          font-size: 12px;
      }
    }
  }
  @media (max-width:767.98px) {
    .victory-wrapper{
      h5.display-3{
        line-height: 3.56vw;
        font-size: 2.67vw;
      }
    }
    .collect-coins .modal-body .last-date {
      font-size: 2.83vw !important;
    }
  }
 
}
