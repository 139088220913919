p {
  font-size: 2.4rem;
  line-height: 2.8rem;
  font-weight: 500;
}
small,
.small {
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 500;
}

// Type display classes
.display-1 {
  line-height: 1.08;
}
.display-2 {
  line-height: 2.8rem;
}
.display-3 {
  line-height: 2.4rem;
}
@media (max-width: 575.98px) {
  p {
    font-size: 2rem;
    line-height: 2.4rem;
  }
  //
  // Headings
  //

  h1,
  .h1 {
    @include font-size(4.4rem);
  }
  h2,
  .h2 {
    @include font-size(4.4rem);
  }
  h3,
  .h3 {
    @include font-size(2.4rem);
  }
  h4,
  .h4 {
    @include font-size(2rem);
  }
  h5,
  .h5 {
    @include font-size(1.6rem);
  }
  h6,
  .h6 {
    @include font-size(2rem);
  }
  // Type display classes
  .display-1 {
    @include font-size(2.4rem);
    // line-height: 2.8rem;
  }
  .display-2 {
    @include font-size(2rem);
    line-height: 2.4rem;
  }
  .display-3 {
    @include font-size(1.6rem);
    line-height: 2rem;
  }
}
