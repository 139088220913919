html,
body {
  font-size: 10px;
  font-weight: normal;
  height: 100%;
  min-height: 100%;
}
#root {
  height: 100%;
  min-height: 100%;
}
img {
  max-width: 100%;
}
.container,
.container-fluid {
  width: 100% !important;
  max-width: 1440px !important;
  padding: 0 32px;
  margin: 0 auto;
}
.xy-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
