.top-container.tu-privacy-policy {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    height: 100vh;
    margin: 10px;
    background-color: white;
    color: black;

}

.tu-privacy-policy .container {
    width: 1200px;
    /* padding-right:200px;
    padding-left:300px; */
    margin-top: 20px;
    /* margin-bottom:20px; */
}

.tu-privacy-policy p {
    font-size: 21px;
    line-height: 26px;
    font-family: "Calibri, sans-serif";
}

.tu-privacy-policy h1 {
    font-size: 21px;
    font-family: "Calibri, sans-serif";
}


.tu-privacy-policy .content {
    padding-right: 200px;
    padding-left: 200px;
    padding-bottom: 20px;
}

.tu-privacy-policy .first {
    margin-top: 40px;
    margin-bottom: 40px;
}

.tu-privacy-policy .part {
    margin-top: 25px;
    margin-bottom: 25px;
}


@media screen and (max-width: 1200px) {
    .tu-privacy-policy .content {
        padding-right: 10px;
        padding-left: 10px;
    }

    .tu-privacy-policy .container {
        width: 100%;

    }
}