// Override Bootstrap's Sass default variables
$enable-responsive-font-sizes: true;

// Color system

$grid-gutter-width: 32px !default;

$primary: #CE0E2D;
$secondary: #009edb;
$danger: #b80f2b;
$success: #55c043;
$info: #0876bb;
$warning: #fdb913;
$light: #ffffff;
$dark: #000000;

$theme-colors: (
  'tertiary': #083983,
  'muted': #d07870,
);

// Customize some defaults
$body-color: #ffffff;
$body-bg: #083983;
$border-radius: 0.4rem;
$btn-hover-bg: #ff0000;
$modal-bg: #00A0E0;
// Spacing
$spacer: 8px;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.5,
  ),
  2: $spacer,
  3: (
    $spacer * 1.5,
  ),
  4: (
    $spacer * 2,
  ),
  5: (
    $spacer * 2.5,
  ),
  6: (
    $spacer * 3,
  ),
  7: (
    $spacer * 3.5,
  ),
  8: (
    $spacer * 4,
  ),
  9: (
    $spacer * 4.5,
  ),
  10: (
    $spacer * 5,
  ),
  11: (
    $spacer * 5.5,
  ),
  12: (
    $spacer * 6,
  ),
  13: (
    $spacer * 6.5,
  ),
  14: (
    $spacer * 7,
  ),
  15: (
    $spacer * 7.5,
  ),
  16: (
    $spacer * 8,
  ),
);

// Typography

$font-size-base: 1rem;
$font-family-base: 'Thums Up';
$headings-font-family: 'Thums Up';
$headings-font-weight: 500;
$headings-margin-bottom: $spacer * 2 !default;

$small-font-size: 80% !default;

$h1-font-size: 6.4rem;
$h2-font-size: 5.2rem;
$h3-font-size: 4.4rem;
$h4-font-size: 3.2rem;
$h5-font-size: 2.4rem;
$h6-font-size: 2rem;

$display1-size: 4.8rem;
$display2-size: 2.4rem;
$display3-size: 2rem;

$display1-weight: bold;
$display2-weight: bold;
$display3-weight: normal;

// Links color
$link-color: #231f20;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: 1.2rem;
$btn-padding-x: 3.6rem;
$btn-font-size: 3.2rem;
$btn-line-height: 3.2rem;

$btn-padding-y-sm: 0.9rem;
$btn-padding-x-sm: 1.5rem;
$btn-font-size-sm: 2.4rem;
$btn-line-height-sm: 2.4rem;

$btn-border-width: 0;

$btn-font-weight: unset;
$btn-box-shadow: unset;
$btn-focus-width: 0;
$btn-disabled-opacity: unset;

$btn-link-disabled-color: muted;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: 1rem;
$btn-border-radius-sm: 0.8rem;

// Modal
$modal-md: 490px !default;
$modal-backdrop-bg: #150100 !default;
$modal-backdrop-opacity: 0.8 !default;
