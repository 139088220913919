.kn_global {
  .arena {
    .section {
      @media (max-width: 992px) {
        &-desc {
          font-size: 2.2vw !important;
        }
      }
      @media (max-width: 400px) {
        &-title {
          font-size: 2rem !important;
        }
      }
    }
  }
  .section-title-main-home{
    font-size: 1.9vw !important;
    @media (max-width:767.98px) {
      font-size: 4.2vw !important;
    }
  }
  .section {
    &-desc {
      line-height: 1.5;
    }

    &-title {
      line-height: 1.2;
      font-size: 3rem;
    }
    &-sub-title {
      font-size: 2rem;
    }
  }

  .tu-language-select-side {
    font-size: 16px;
  }

  .tu-language {
    &::after {
      top: 4px;
    }
  }

  .tu-language-select {
    &::after {
      right: -0.6vw;
    }
  }

  .btn {
    &-prev,
    &-next {
      font-size: 2rem !important;

      @media (max-width: 767.98px) {
        font-size: 1.3rem !important;
      }
    }
  }

  .contest-btn {
    &-left {
      font-size: 2.8rem !important;

      @media (max-width: 850px) {
        font-size: 2.5rem !important;
      }

      @media (max-width: 767.67px) {
        font-size: 2rem !important;
        padding: 2vw 2.2vw !important;
      }

      @media (max-width: 500px) {
        font-size: 16.5px !important;
        padding: 3vw 2.2vw !important;
      }
    }
  }

  .card-block-how-to-win-merch-section {
  
    @media (max-width: 991.67px) {
    min-height: unset;
    padding-top: 12px !important;
    }
    @media (max-width: 767.67px) {
      padding: 2rem 0.5rem !important;
    }
  }

  .header {
    .tu-unique-code-wrapper {
      .btn {
        font-size: 13px;
      }

      input {
        font-size: 12px;
      }

    

      @media (max-width: 992px) {
        margin-right: 30px;

        .btn {
          font-size: 9px;
        }

        input {
          font-size: 12px;
        }
      }

      @media (max-width: 400px) {
        margin-left: 0;
        margin-right: 10px;
      }

      @media (max-width: 350px) {
        input {
          font-size: 3vw !important;
        }

        .btn {
          font-size: 1.7vw !important;
        }
      }

      @media (max-width: 767px) {
        .btn {
          font-size: 13px !important;
        }

        input {
          font-size: 13px !important;
          padding-left: 6px;
          width: 16.5rem;
        }
      }

      @media (max-width: 500px) {
        margin-right: 10px;
        margin-left: 0;
        .btn {
          font-size: 12px !important;
        }

        input {
          font-size: 13px !important;
          padding-left: 6px;
          width: 13rem;
        }
      }
      @media (max-width: 350px) {
        input {
          width: 10rem;
        }
      }
    }

    .tu-language-select {
      &::after {
        // right: -0.8vw !important;
        @media (max-width: 767.67px) {
          right: -1.5vw !important;
        }
      }
    }
  }

  .tu-select-wrapper {
    select.form-control {
      @media (max-width: 432px) {
        font-size: 14px;
      }
    }
  }

  .tu-wallet-link {
    font-size: 2rem !important;

    @media (max-width: 841px) {
      margin-right: 30px;
      font-size: 13px !important;
    }
    @media (max-width: 500px) {
      margin-right: 10px;
    }

    @media (max-width: 381px) {
      font-size: 9px !important;
      margin-right: 5px;
    }
  }

  .tu-unique-code-wrapper {
    @media (max-width: 841px) {
      margin-right: 30px;

      .btn,
      input {
        font-size: 11px !important;
      }
    }

    @media (max-width: 381px) {
      margin-right: 5px;
      margin-left: 0;

      .btn,
      input {
        font-size: 10px !important;
      }
    }
  }

  .refer-modal {
    .modal-content {
      .modal-body {
        .modal-right-section {
          .claim-head {
            font-size: 18px;
            line-height: 32px;
          }
        }
      }
    }
  }
  .banner {
    //fixes on howToWin ctas

    @media (min-width: 992px) and (max-width: 1110px) {
      .card {
        &-block {
          &-sm {
            .card {
              &-block {
                &-img-top {
                  max-width: 10.5rem;
                }
              }
            }
          }
        }
      }

      .btn-sm {
        &-ticket,
        &-merch {
          margin-bottom: 1rem;
          font-size: 1.5rem;
          line-height: 1;
          min-height: 3.6vh;
        }

        &-merch {
          padding-top: 1.3vh;
          padding-bottom: 1rem;
        }
      }
    }

    @media (min-width: 430px) and (max-width: 500px) {
      .card {
        &-block {
          .btn {
            &-primary {
              line-height: 1.1 !important;
            }
          }
        }
      }
    }

    @media (max-width: 363px) {
      .card {
        &-block {
          .btn {
            &-primary {
              line-height: 1.2 !important;
              padding: 0 1vw;
            }
          }
        }
      }
    }

    @media (max-width: 767px) {
      .card {
        &-block {
          &-how-to-win-merch {
            min-height: 4vh;
          }
        }
      }

      .btn-sm {
        &-ticket {
          line-height: 1.2;
        }
      }
    }

    @media (max-width: 500px) {
      .card {
        &-block {
          &-how-to-win-merch {
            min-height: 5.8vh !important;
          }
        }
      }
    }

    &-content-body {
      @media (min-width: 767.98px) {
        max-width: 42vw;
      }
    }

    &-img {
      @media (max-width: 500px) {
        max-height: 28rem !important;
      }

      @media (max-width: 380.98px) {
        min-height: 26rem;
      }
    }

    .section {
      &-title {
        line-height: 1.4;

        @media (max-width: 1199.98px) {
          &-howToWin {
            margin-top: 5rem;
          }

          font-size: 3vw;
        }

        @media (max-width: 767.98px) {
          font-size:4.1vw;
        }
      }

      &-sub-title {
     

        @media (max-width: 575.98px) {
          font-size: 3.2vw;
        }
      }
    }
.card-block-how-to-win-merch{
  padding-top: 2rem !important;
}
    .card-block-how-to-win-merch,
    .ticket-win {
      min-height: 66px;
      padding-top: 1rem;
      padding-left: 2rem !important;
      padding-right: 2rem !important;
      max-width: 30rem;
      line-height: 1.4;
      font-size: 1.5rem;
      margin: 0 10px;
    



      @media (max-width: 767.98px) {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 8vh !important;
      margin: 0 4px;
      padding: unset !important;
      min-height: 10vh !important
      }

   
    }

    .card-block-how-to-win-merch {
  

      @media (max-width: 575.98px) {
    
      }
    }

    @media (max-width: 500px) {
      .banner-collect {
        padding-right: 2px !important;
        margin-bottom: 0;
      }
    }
  }

  //sidebar section
  .tu-sidebar-privacy-main {
    @media (max-width: 500.98px) {
      padding-left: 22px;
    }
  }

  .tu-language-side-bottom-bar::after {
    @media (max-width: 500.98px) {
      left: 25px;
    }
  }

  .tu-sidebar-list-main {
    ul {
      @media (max-width: 500.98px) {
        padding-left: 22px;
      }

      li {
        a {
          font-size: 13px;

          span {
            font-size: 10px !important;
          }
        }
      }
    }
  }

  .logoutbtn {
    font-size: 2rem !important;
  }

  .claim-btn {
    font-size: 1.4rem !important;
    line-height: 24px !important;
  }

  .small {
    font-size: 1.5rem;
  }

  .btn {
    font-size: 3.4rem;

    &-sm {
      font-size: 1.35rem;
    }

    .claim-btn {
      font-size: 1.4rem !important;
      line-height: 24px !important;
    }

    .btn {
      font-size: 2.8rem;

      &-sm {
        font-size: 1.35rem;
      }

      &-prev {
        font-size: 2.5rem;
        padding: 1.6rem 2rem;
      }

      &-next {
        font-size: 2.5rem;
        padding: 1.6rem 2rem;
      }
    }

    @media (min-width: 657px) and (max-width: 767.67px) {
      .btn {
        font-size: 2.2rem;

        &-sm {
          font-size: 1.1rem;
        }

        &-prev {
          font-size: 1.8rem;
          padding: 1.4rem 1.8rem;
        }

        &-next {
          font-size: 1.8rem;
          padding: 1.4rem 1.8rem;
        }
      }
    }

    @media (min-width: 320px) and (max-width: 656px) {
      .btn {
        font-size: 1.2rem;

        &-prev {
          font-size: 1.4rem;
        }

        &-next {
          font-size: 1.4rem;
        }
      }

      .vote-now-btn {
        padding: 1.5rem 1.5rem;
        font-size: 1.6vw;
        word-wrap: break-word;
        line-height: 1.3;
        font-weight: 570;
      }

      .unique-code-btn {
        padding: 1.1rem 1.5rem;
        font-size: 2.3vw;
        word-wrap: break-word;
        line-height: 1.4;
        font-weight: 570;
      }
    }
  }

  .tu-content-wrapper {
    &.tu-first {
      .section-title {
        font-size: 4rem;
        // line-height: 1.4;
      }
    }

    .section {
      &-desc {
        font-size: 2rem;
        line-height: 1.4;

        @media (max-width: 500px) {
          font-size: 1.5rem;
        }
      }
    }

    .tu-country-select {
      span {
        font-size: 20px;
      }
    }
  }

  .card {
    &-block {
      &-how-to-win-merch-section {
        font-size: 2.4rem;
      }
    }
  }

  .invalid-feedback,
  .error_message {
    line-height: 1.3;
    // padding-top: 5px;
    font-size: 1.4rem;
    padding: 0.5rem 0 0 0;
  }

  .modal-title {
    font-size: 26px;
    line-height: 45px;
  }

  .modal-sub-title {
    margin-top: 0 !important;
    font-size: 18px;
  }

  .form-label {
    line-height: 1.4;
    font-size: 18px;
  }

  .votenowbtn {
    line-height: 3rem;
  }

  .modal {
    &-right-section {
      .btn {
        font-size: 2.5rem;

        @media (max-width: 500px) {
          font-size: 2rem;
        }

        @media (max-width: 399px) {
          font-size: 1.5rem;
        }
      }
    }

    &-wallet .caption-title {
      font-size: 1.8rem;
      min-height: 84px;
      line-height: 1.4;

      @media (max-width: 530.98px) {
        font-size: 1.2rem;
        min-height: 54px;
      }

      @media (max-width: 384.98px) {
        font-size: 1rem;
      }

      @media (max-width: 325.98px) {
        min-height: 74px;
      }
    }

    &-wallet {
      .btn {
        line-height: 1.4;
      }

      .vote-now-btn,
      .unique-code-btn {
        font-size: 1.6rem;
        min-height: 64px;
        height: 64px;

        @media (max-width: 767.98px) {
          font-size: 1.1rem;
          min-height: 52px;
        }

        @media (max-width: 438.98px) {
          min-height: 62px;
          font-size: 1rem;
        }
      }
    }

    &-wallet {
      .caption-title {
        font-size: 1.5rem;

        @media (max-width: 761px) and (min-width: 595px) {
          &-right {
            font-size: 2.6rem;
          }

          &-left {
            font-size: 2rem;
          }
        }

        @media (max-width: 594px) and (min-width: 514px) {
          &-right {
            font-size: 2rem;
          }

          &-left {
            font-size: 1.7rem;
          }
        }

        @media (max-width: 513px) and (min-width: 473px) {
          &-right {
            font-size: 1.67rem;
          }

          &-left {
            font-size: 1.67rem;
          }
        }

        @media (max-width: 435px) {
          &-left {
            font-size: 1.3rem;
          }

          &-right {
            font-size: 1.5rem;
          }
        }

        @media (max-width: 425px) {
          font-size: 1.2rem;
          line-height: 1.2;
          padding-bottom: 1vh;

          &-right {
            font-size: 1.4rem;
          }
        }

        @media (max-width: 375.98px) {
          font-size: 1.2rem;
          line-height: 1.2;
          padding-bottom: 1vh;

          &-right {
            font-size: 1.4rem;
          }
        }

        @media (max-width: 366.98px) {
          font-size: 1rem;
          line-height: 1.2;
          padding-bottom: 1vh;

          &-right {
            font-size: 1.4rem;
          }
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .section {
      &-title {
        font-size: 3.2vw;
        line-height: 4.5vw;
      }
    }

    .tu-content-wrapper {
      &.tu-first {
        .section-title {
          font-size: 44px;
          line-height: 5vw;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .tu-content-wrapper {
      &.tu-first {
        .section-title {
          font-size: 4rem;
          line-height: 1.4;
        }
      }
    }

    .tu-dailytoss-section .vector-right {
      left: 95%;
    }

    .footer p {
      font-size: 2vw;
    }
  }

  @media (max-width: 767.67px) {
    .section {
      &-title {
        font-size: 3.5vw;
        line-height: 5.5vw;
      }
    }

    .signup-privacy {
      font-size: 2vw;
      line-height: 13px;
    }

    .contest-btn {
      font-size: 2.4rem;
    }

    .error_message {
      padding-top: 1rem;
      font-size: 2vw;
    }

    .meter-sub-heading {
      font-size: 18px;
    }

    .tu-content-wrapper {
      &.tu-first {
        .section-title {
          font-size: 32px;
          line-height: 10vw;
        }
      }

      .tu-content-wrapper .btn {
        font-size: 14px;
      }
    }

    .tu-middle-section {
      .vector-right {
        width: 27vw;
        right: 0%;
      }
    }

    .tu-dailytoss-section .vector-right {
      width: 25vw;
      left: 91%;
    }

    .ticket-win {
      // font-size: 3vw !important;
      padding: 1.2vw 5vw !important;
    }

    .card {
      &-block {
        &-how-to-win-merch-section {
          font-size: 2rem !important;
        }

        &-how-to-win-merch {
          font-size: 3vw;
        }
      }
    }

    .banner-collect,
    .ar-cta {
      padding-right: 1vw !important;
    }

    .vote-now-btn {
      font-size: 2vw;
      word-wrap: break-word;
      line-height: 1.3;
      padding: 1.4rem 1.5rem;
    }

    .unique-code-btn {
      font-size: 2.5vw;
      word-wrap: break-word;
      line-height: 1.4;
      padding: 2.1rem 1.5rem;
    }
  }

  @media (max-width: 575px) {
    .footer p {
      font-size: 3vw;
    }

    .contest-btn {
      font-size: 3vw !important;
      height: 12vw;
    }

    .card {
      &-block {
        &-how-to-win-merch-section {
          font-size: 3vw !important;
          height: 12vw;
        }

        &-how-to-win-merch {
          font-size: 3vw !important;
        }
      }
    }

    .claim-btn {
      font-size: 1rem !important;
      line-height: 1.4 !important;
      margin: 0 8px;
      padding: 0.6rem !important ;
    }
  }

  @media (max-width: 550px) {
    .ticket-win {
      font-size: 3vw !important;
      padding: 2vw 5vw !important;
    }

    .tu-content-wrapper {
      &.tu-first {
        .section-title {
          font-size: 28px;
          line-height: 1.4;
        }
      }
    }

    .signup-privacy {
      font-size: 3vw;
      line-height: 13px;
    }

    .error_message {
      font-size: 3vw;
      line-height: 1.4;
    }

    .tu-content-wrapper .btn {
      font-size: 14px;
    }

    .tu-middle-section {
      .vector-right {
        width: 27vw;
        right: 0%;
        bottom: -8%;
      }
    }

    .tu-dailytoss-section {
      .vector-right {
        width: 19vw;
        left: 89%;
      }
    }

    .modal-title {
      font-size: 28px;
      line-height: 45px;
    }

    .modal-sub-title {
      font-size: 14px;
      line-height: 16px;
    }

    .form-label {
      line-height: 1;
      font-size: 15px;
    }

    .vote-now-btn {
      font-size: 2vw;
      word-wrap: break-word;
      line-height: 1.3;
      padding: 1.1rem 1.5rem;
    }

    .unique-code-btn {
      font-size: 2.4vw;
      word-wrap: break-word;
      line-height: 1.4;
      padding: 2.5rem 1.5rem;
    }
  }

  @media (max-width: 430px) {
    .section {
      &-desc {
        font-size: 3.5vw;
      }
    }

    .section-title {
      font-size: 2.5rem;
      line-height: 1.4;
    }

    .tu-middle-section {
      .vector-right {
        width: 27vw;
        right: 0%;
        bottom: -21%;
      }
    }

    .meter-sub-heading {
      font-size: 15px;
    }

    .tu-dailytoss-section {
      .vector-right {
        width: 19vw;
        left: 80%;
      }
    }

    .modal-title {
      font-size: 20px;
      line-height: 30px;
    }

    .modal-sub-title {
      font-size: 14px;
      line-height: 19px !important;
    }

    .form-label {
      line-height: 1.2;
      font-size: 16px;
    }

    .vote-now-btn {
      font-size: 3vw;
      word-wrap: break-word;
      line-height: 1.3;
    }

    .unique-code-btn {
      font-size: 3.2vw;
      word-wrap: break-word;
      line-height: 1.4;
    }

    .contest-btn {
      &-kn {
        padding: 3vw 4vw !important;
        line-height: 1.2;
        min-height: 13vw;
      }

      font-size: 2.9vw !important;
    }

    .card {
      &-block {
        &-how-to-win-merch-section {
          padding-top: 2rem !important;
          font-size: 1rem !important;
        }
      }
    }
  }

  @media (max-width: 398px) {
    .ticket-win {
      font-size: 3vw !important;
      padding: 3.1vw 5vw !important;
    }

    .banner-collect {
      padding-right: 2vw !important;
    }

    .ar-cta {
      padding-right: 1vw !important;
    }

    .tu-content-wrapper {
      &.tu-first {
        .section-title {
          font-size: 24px;
          line-height: 10vw;
        }
      }
    }

    .contest-btn {
      &-left {
        padding: 3.5vw 3vw !important;
        font-size: 1rem !important ;
      }
      &-kn {
        padding: 2.5vw 3vw;
        line-height: 1.2;
        height: 18vw !important;
      }

      font-size: 1.3rem !important;
    }

    .card {
      &-block {
        &-how-to-win-merch-section {
          padding-top: 1.9rem !important;
          font-size: 1.3rem !important;
        }
      }
    }
  }

  @media (max-width: 375.98px) {
    .contest-btn {
      &-kn {
        padding: 4.5vw 2.5vw !important;
        line-height: 1.2;
        min-height: 13vw;
      }

      font-size: 1.3rem;
    }

    .card {
      &-block {
        &-how-to-win-merch-section {
         display: flex;
         justify-content: center;
         align-items: center;
          font-size: 2.9vw !important;
        }
      }
    }
  }

  // tu profile dropdown

  .tu-profile-dropdown {
    .dropdown-menu {
      background: $secondary;
      min-width: 337px;
      width: 100%;
      height: 88vh;
      padding: 22px 0 0 0;
      border-radius: 15px;

      @media (max-height: 1000px) {
        overflow-y: auto;
        // padding-bottom: 100px;
        height: 71vh;
        overscroll-behavior: contain;
      }
    }

    .dropdown-toggle {
      &:after {
        display: none;
      }

      .thumbnail-image {
        position: relative;
        z-index: 2;
      }
    }

    ul.tu-user-details {
      list-style: none;
      display: block;
      padding: 0 22px;

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .avtar-select {
          position: absolute;
          top: 5rem;
          left: 5.5rem;
          border: 1px solid $light;
          border-radius: 50%;
          width: 20px;
          background: #cecece;
          height: 20px;
          padding: 0px;

          img {
            width: 6px;
            height: 6px;
          }
        }

        img.thumbnail-image {
          @media only screen and (max-width: 767px) {
            width: 50px !important;
            height: 50px !important;
          }
        }

        .tu-input-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          span {
            font-family: Thums Up;
            font-size: 12px !important;
            font-weight: 700;
            letter-spacing: 0px;
            color: #002169;
            text-align: left;
            white-space: nowrap;
            min-width: 75px;
          }

          .error_message {
            font-size: 16px;
            color: #fff;
            // padding-left: 10px;
          }

          .select_message {
            padding-left: 5px;
          }

          .select-wrapper {
            flex: 1;
            margin-right: 1rem;
            margin-left: 6px;
            position: relative;

            &::after {
              content: '';
              border-bottom: 1px solid #fff;
              width: 141px;
              height: 1px;
              display: block;
              top: 26px;
              position: absolute;
              left: 4px;
            }
          }

          select {
            background: transparent;
            font-size: 1.5rem;
            color: #fff;
            font-weight: 600;
            width: calc(100% - 20px);
            border: 0;
            border-radius: 0;
            padding: 0 10px 0 0;
            height: 32px;

            &:focus {
              outline: 0;
              box-shadow: none;
              border-color: inherit;
            }

            option {
              font-size: 1.8rem;
              background-color: #009edb;
              color: #fff;
            }
          }
        }

        input.tu-user-input {
          background: transparent;
          border: none;
          font-size: 18px;
          line-height: 23px;
          color: $light;
          font-weight: 700;
          width: calc(100% - 20px);
          padding-left: 0;
          display: block;
          //text-transform: capitalize;

          &:disabled {
            padding-left: 10px;
            opacity: 1;
            -webkit-text-fill-color: $light;
          }

          &.edit {
            border-bottom: 1px solid $light;
            border-radius: 0;
            // margin-left: 10px;
            width: 100%;
            max-width: calc(100% - 40px);
          }

          &:focus-visible {
            outline: none;
          }

          &::placeholder {
            color: $light;
            font-weight: 700;
          }
        }

        button {
          background: transparent;
          border: none;
        }

        .tu-save-btn {
          color: $primary;
          background-color: $light;
          border-color: $light;
          width: 28px;
          font-size: 17px;
          border-radius: 4px;
          height: 25px;
          line-height: 10px;
        }
      }
    }
  }

  .tu-coins-section,
  .tu-referrals-section {
    .tu-content-row {
      display: flex;
      justify-content: space-between;

      .tu-content {
        display: flex;
        justify-content: space-between;
        width: 65%;

        p {
          font-size: 14px;
          line-height: 28px;
          color: $light;
          font-weight: 600;
          margin-bottom: 3px;
          text-align: left;
          min-width: 25px;
        }
      }

      .btn {
        width: fit-content;
        height: fit-content;
        font-size: 1.2rem;
        line-height: 22px;
        padding: 3px 6px;
        border-radius: 4px;
      }
    }
  }

  // collect-coins modal
  .collect-coins {
    .modal {
      &-body {
        h2 {
          font-size: 23px;
          line-height: 1.5;
        }
        .victory-wrapper {
          h4 {
            @media only screen and (max-width: 481px) {
              font-size: 20px;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 438px) and (min-width: 418px) {
    .collect-coins {
      .modal {
        &-body {
          .match-coin-btns {
            button {
              font-size: 1.1rem;
            }
          }
        }
      }
    }
  }

  .silver-coins {
    .display-1 {
      line-height: 1.4;
    }

    @media only screen and (max-width: 400px) {
      .btn {
        margin: 0 !important;
        font-size: 1rem !important;
      }
    }
  }

  .profile-success {
    .modal-content {
      padding: 0;
      background-image: url('~/public/assets/images/model-background-success.png');

      @media only screen and (max-width: 575px) {
        background-image: url('~/public/assets/images/m-profile-bg.png') !important;
      }

      .modal-bg {
        .modal-body {
          h4 {
            @media only screen and (max-width: 575px) {
              max-width: 295px;
              font-size: 20px;
              margin-left: auto;
              margin-right: auto;
            }
          }

          .tu-input-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            span {
              font-family: Thums Up;
              font-size: 12px !important;
              font-weight: 700;
              letter-spacing: 0px;
              color: #002169;
              text-align: left;
              white-space: nowrap;
              min-width: 75px;
            }

            .error_message {
              font-size: 16px;
              color: #fff;
              // padding-left: 10px;
            }

            .select_message {
              padding-left: 5px;
            }

            .select-wrapper {
              flex: 1;
              margin-right: 1rem;
              margin-left: 6px;
              position: relative;

              &::after {
                content: '';
                border-bottom: 1px solid #fff;
                width: 141px;
                height: 1px;
                display: block;
                top: 26px;
                position: absolute;
                left: 4px;
              }
            }

            select {
              background: transparent;
              font-size: 1.5rem;
              color: #fff;
              font-weight: 600;
              width: calc(100% - 20px);
              border: 0;
              border-radius: 0;
              padding: 0 10px 0 0;
              height: 32px;

              &:focus {
                outline: 0;
                box-shadow: none;
                border-color: inherit;
              }

              button.btn {
                @media only screen and (max-width: 575px) {
                  max-width: 233px;
                  display: block;
                  width: 100%;
                  margin: 0 auto;
                  font-size: 20px;
                }

                input.tu-user-input {
                  background: transparent;
                  border: none;
                  font-size: 22px;
                  line-height: 23px;
                  color: $light;
                  font-weight: 700;
                  width: calc(100% - 20px);
                  padding-left: 0;
                  display: block;
                  //text-transform: capitalize;

                  &:disabled {
                    padding-left: 10px;
                    opacity: 1;
                    -webkit-text-fill-color: $light;
                  }

                  &.edit {
                    border-bottom: 1px solid $light;
                    border-radius: 0;
                    // margin-left: 10px;
                    width: 100%;
                    max-width: calc(100% - 40px);
                  }

                  &:focus-visible {
                    outline: none;
                  }

                  &::placeholder {
                    color: $light;
                    font-weight: 700;
                  }
                }

                button {
                  background: transparent;
                  border: none;
                }

                .tu-save-btn {
                  color: $primary;
                  background-color: $light;
                  border-color: $light;
                  width: 28px;
                  font-size: 17px;
                  border-radius: 4px;
                  height: 25px;
                  line-height: 10px;
                }
              }
            }
          }

          .tu-coins-section,
          .tu-referrals-section {
            .tu-content-row {
              display: flex;
              justify-content: space-between;

              .tu-content {
                display: flex;
                justify-content: space-between;
                width: 65%;

                p {
                  font-size: 16px;
                  line-height: 28px;
                  color: $light;
                  font-weight: 600;
                  margin-bottom: 3px;
                  text-align: left;
                  min-width: 25px;
                }
              }

              .btn {
                width: fit-content;
                height: fit-content;
                font-size: 1.2rem;
                line-height: 22px;
                padding: 3px 6px;
                border-radius: 4px;
              }
            }
          }
        }
      }

      // collect-coins modal
      .collect-coins {
        .modal {
          &-body {
            .victory-wrapper {
              h4 {
                @media only screen and (max-width: 481px) {
                  font-size: 20px;
                }
              }
            }
          }
        }
      }
    }
  }

  //logout btn fixes

  .logout-btn {
    @media (max-width: 855px) {
      font-size: 1.8rem !important;
      padding: 1.4vw 4vw;
    }

    @media (max-width: 1757px) {
      font-size: 2.5rem;
    }

    @media (max-width: 575px) {
      padding: 0.5rem 1.2rem !important;
      font-size: 1.7rem !important;
    }

    @media (max-width: 501px) {
      padding: 0.5rem 1.2rem !important;
    }

    @media (max-width: 338px) {
      padding: 0.5rem 1.2rem !important;
      font-size: 1.6rem !important;
    }
  }

  // AR banner
  .tu-ar-banner {
    @media (min-width: 320px) and (max-width: 767.98px) {
      width: 84vw !important;
      margin-left: auto;
      display: flex;
      transform: translate(-24%, -50%);
      flex-direction: column;
      align-items: center;
    }

    @media (min-width: 320px) and (max-width: 767.98px) {
      .ar-cta {
        margin-right: 0;
        padding-right: 0;

        a {
          font-size: 12px;
          padding: 7px;
        }
      }
    }

    .ar-title {
      @media (min-width: 320px) and (max-width: 767.98px) {
        font-size: 5.5vw !important;
      }
    }
  }

  @media (max-width: 575.98px) {
    .winner-contest {
      .list-item {
        h6 {
          font-size: 1.7vw !important;
        }
      }
    }

    .section {
      &-sub-title {
        a.table-link {
          font-size: 3.7vw;
        }
      }
    }
  }

  .winner-contest {
    .list {
      &-btns {
        font-size: 2rem !important;
        padding: 1.2rem 2rem;

        @media (max-width: 1200px) {
          font-size: 1.3rem !important;
          padding: 0.8rem 1.7rem;
        }

        @media (max-width: 435px) {
          font-size: 1rem !important;
          padding: 0.7rem 1.3rem;
        }
      }
    }
  }
  .no_ball {
    .modal-bg {
      .modal-body {
        h2 {
          @media (max-width: 575px) {
            font-size: 36px;
          }
        }
        h4 {
          font-size: 28px;
          @media (max-width: 575px) {
            font-size: 21px;
          }
          @media (max-width: 395px) {
            font-size: 15px;
          }
        }
      }
    }
  }
  .collect-coins .modal-body .bottom-txt {
    font-size:3.1vw !important;
    line-height: 4.5vw;
}
@media (max-width:767px) {
  .collect-coins .modal-body .last-date {
  font-size: 3.8vw !important;
}
.winner-contest .list-avatar{
  max-width: 6.5vw;

}
@media (max-width:430px) {
  .winner-contest .list-avatar{
    max-width: 7vw;
  }
  
}
}

}