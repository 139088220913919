.modal {
  &* {
    transition: height 10.6s ease;
  }

  &-dialog {
    width: calc(100% - 30px);
    margin: 0 auto;
  }

  &-bg1 {
    .modal-dialog {
      max-width: 666px;

      .modal-content {
        @media only screen and (min-width: 576px) and (max-width: 700px) {
          padding-bottom: 0;
        }

        @media only screen and (max-width: 575px) {
          .modal-body {
            margin-top: -50px;
          }

          .row {
            display: block;
            .col-3 {
              max-width: 100%;
            }

            .col-3,
            .col-9 {
              width: 100%;
              margin: 0 auto;
            }
          }
        }

        .modal-title {
          max-width: 320px;
        }

        @media only screen and (max-width: 575px) {
          .modal-right-section {
            max-width: 300px;
            margin: 0 auto;

            .modal-title {
              max-width: 100%;
            }
          }
        }

        @media only screen and (max-width: 425px) {
          .text-sm-left {
            .modal-left-img {
              min-height: 0px;
            }
          }
        }

        .invalid-feedback {
          color: $light;
        }
      }
    }
  }

  &-md {
    max-width: 590px;
  }

  &-sm {
    max-width: 590px;
  }

  &-bg {
    &1 {
      .modal-content {
        background-image: url('~/public/assets/images/model-background-1.png');
      }
      .modal-left-img {
        max-width: 180px;

        @media (max-width : 575px){
          max-width: 130px;
          padding-top: 32px;
          padding-bottom: 10px;
        }
      }
    }

    &2 {
      .modal-content {
        background-image: url('~/public/assets/images/model-background-2.png');
      }
    }

    &3 {
      .modal-content {
        background-image: url('~/public/assets/images/model-background-3.png');

        .modal-body {
          .modal-left-img {
            top: 9px;
          }

          padding-bottom: 24px !important;

          .modal-title {
            padding-right: 9rem;
            line-height: 48px;
            margin-bottom: 0;
          }

          .modal-sub-title {
            margin-top: 25px;
            padding-right: 9rem;
            line-height: 28px;
          }

          .error_message {
            @media only screen and (max-width: 600px) {
              font-size: 16px;
              text-align: left;
            }
          }
        }
      }
    }

    &4 {
      .modal-content {
        background-image: url('~/public/assets/images/model-background-5.png');
        .form-check-label {
          padding-left: 18px;
          padding-right: 50px;
          font-size: 20px;
          line-height: 1.1;

          @media only screen and (max-width: 425px) {
            font-size: calc(1.325rem + 0.9vw);
          }

          a {
            color: white;
            text-decoration: underline;
          }
        }
      }

      .modal {
        &-left-img {
          top: 7%;
        }
      }
    }

    .contact-modal,
    &2,
    &3,
    &4 {
      .modal {
        &-left-img {
          position: absolute;
          left: 8px;
        }
      }
    }
  }

  &-bg2 {
    .modal-header {
      padding-top: 6px !important;
    }

    .modal-body {
      padding: 0 4px 20px 4px !important;

      .modal-title {
        margin-bottom: 2px;
      }
    }
  }

  &-invite {
    .modal-content {
      background-image: url('~/public/assets/images/model-background-4.png');
    }
  }

  &.refer-modal {
    .modal-dialog {
      max-width: 590px;
    }

    .modal-content {
      background-image: url('~/public/assets/images/refer-bg.png');
    }
  }

  &-invite,
  &.refer-modal {
    .modal-left-img {
      position: absolute;
      left: 8px;
    }

    .row {
      justify-content: unset !important;
    }

    .col {
      &-sm-4 {
        width: 30%;
        padding-left: 0;
      }

      &-sm-7 {
        width: 70%;
      }
    }
  }

  &-claim-ticket {
    .modal-content {
      background-image: url('~/public/assets/images/claim-ticket-bg.png') !important;

      .modal-body {
        padding: 20px 0 15px 0px !important;
      }
    }
  }

  &-content {
    border-radius: 20px;
    background-repeat: no-repeat;
    border-radius: 15px;
    background-size: cover;
    background-color: $secondary;
    background-position: top center;
    padding: 15px 5px 20px 5px;
  }

  &-header {
    border: none;
    justify-content: flex-end;
    padding-top: 6px;
    padding-right: 22px;
    z-index: 3;
  }

  &-body {
    padding: 2px 4px 0 4px;
  }

  &-title {
    font-size: 48px;
    font-weight: 700;
    line-height: 45px;
    margin-bottom: 8px;
  }

  &-sub-title {
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
  }

  &-footer {
    border: none;
  }

  .mandatory {
    &-text {
      font-size: 32px;
    }

    .privacy-condition {
      font-size: 24px;
    }
  }

  .dropdown-container {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
  }

  @media only screen and (max-width: 575px) {
    &-bg1 {
      .modal-content {
        background-image: url('~/public/assets/images/m-model-background-1.png') !important;
      }
    }

    &-bg2 {
      .modal-content {
        background-image: url('~/public/assets/images/m-model-background-2.png') !important;
        background-position: left;
        background-size: contain;
      }
    }

    &-bg3 {
      .modal-content {
        background-image: url('~/public/assets/images/m-model-background-3.png') !important;
        background-position: left;
        background-size: contain;
      }

      .modal {
        &-sub-title,
        &-title {
          padding-right: 0rem !important;
        }
      }
    }

    &-bg4 {
      .modal-content {
        background-image: url('~/public/assets/images/m-model-background-5.png') !important;
        // padding-top: 35px;
      }

      .form-check-label {
        padding-right: 0 !important;
      }
    }

    .refer-modal {
      .modal-content {
        background-image: url('~/public/assets/images/m-refer-bg.png') !important;
      }
    }

    &-bg2,
    &-bg3,
    &-bg4 {
      .modal {
        &-title,
        &-sub-title {
          text-align: left;
        }
      }
    }

    &-title {
      text-align: center;
      font-size: 40px;
    }

    &-sub-title {
      text-align: center;
      font-size: 20px;
    }

    &-right-section {
      margin: 0 15px;
    }

    &-left-img {
      max-width: 132px;
      width: 100%;
    }

    &-invite,
    &.refer-modal {
      .modal {
        &-title,
        &-sub-title {
          text-align: left;
        }
      }
    }
  }

  @media only screen and (max-width: 532px) {
    &-bg3 {
      .modal-sub-title {
        padding-right: 11rem !important;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    &-bg3 {
      .modal-content {
        background-size: cover;
      }

      .btn-secondary {
        margin: 0 auto 0 !important;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    &-bg3 {
      .modal-sub-title {
        padding-right: 7rem !important;
      }
    }
  }

  @media only screen and (max-width: 464px) {
    &-bg3 {
      .modal-sub-title {
        padding-right: 6rem !important;
      }
    }
  }

  @media only screen and (max-width: 430px) {
    &-bg2 {
      .modal-content {
        background-image: url('~/public/assets/images/m-model-background-2.png') !important;
        background-position: left;
        background-size: cover;
      }
    }
  }

  @media only screen and (max-width: 430px) {
    &-bg1,
    &-bg2,
    &-bg3,
    &-bg4 {
      .modal {
        &-title {
          font-size: 8.4vw;
          line-height: 8.4vw;
        }
      }
    }

    &-bg3 {
      .modal {
        &-sub-title {
          margin-top: 0px !important;
          padding-right: 30px !important;
        }
      }
    }
  }

  @media only screen and (max-width: 364px) {
    &-bg3 {
      .modal-sub-title {
        padding-right: 2rem !important;
      }
    }
  }

  @media only screen and (max-width: 350px) {
    &-bg3 {
      .modal-sub-title {
        padding-right: 0rem !important;
      }
    }
  }

  @media only screen and (max-width: 325px) {
    &-bg3 {
      .modal {
        &-sub-title {
          margin-top: 0px !important;
          line-height: 24px;
          font-size: 18px;
        }
      }

      .error_red {
        bottom: 25px !important;
      }
    }
  }
}

//win ticket modal start

.win-ticket {
  .modal {
    &-dialog {
      max-width: 590px;
    }

    &-body {
      padding-top: 0;

      h2 {
        font-size: 48px;
      }

      img {
        max-width: 100%;
        margin: 0px auto 35px auto;
      }

      .display-2 {
        line-height: 28px;
        padding: 0 30px;
        font-weight: 400;
        font-size: 23px;
      }

      h3.display-3 {
        font-size: 36px;
        line-height: 40px;
      }

      h4 {
        font-size: 32px;
        line-height: 28px;
      }

      h5 {
        font-size: 28px;
        line-height: 24px;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .modal {
      &-dialog {
        max-width: 390px;
      }

      &-body {
        padding-top: 0;

        h2 {
          font-size: 40px;
          line-height: 44px;
        }

        img {
          max-width: 321px;
          width: 100%;
        }

        .display-2 {
          line-height: 24px;
          padding: 0 30px;
          font-size: 17px;
        }

        h3.display-3 {
          font-size: 32px;
          line-height: 36px;
        }

        h4 {
          font-size: 19px;
          margin-bottom: 5px;
        }

        h5 {
          font-size: 16px;
          line-height: 24px;
        }

        button {
          padding: 0.5rem 2rem;
        }
      }
    }
  }
}

.thanks-win {
  .modal {
    &-body {
      max-width: 463px;
      margin: 0 auto;
      padding: 0;
      /* max-width: 73vw; */
      width: calc(100% - 20%);

      h2 {
        margin-bottom: 0px !important;
      }

      @media only screen and (max-width: 767px) {
        .display-2 {
          padding: 0px !important;
        }
      }

      @media only screen and (max-width: 325px) {
        .display-2 {
          line-height: 20px !important;
        }
      }
    }
  }
}

.backArrow {
  position: absolute;
  top: -44px;
  left: -50%;

  @media (max-width: 575px) {
    left: -26%;
  }
}

.model_top_1 {
  .modal-bg {
    @media only screen and (max-width: 767px) {
    }

    @media only screen and (max-width: 575px) {
      background-image: url('~/public/assets/images/m-model-background-1.png') !important;
      background-size: contain;

      img.modal-left-img {
        width: 128px;
        margin: 0 auto;
        display: inline-block;
      }
    }
  }

  .tu-common-modal-row {
    text-align: center;
  }
}

.modal-bg4 {
  .modal-bg {
    padding-bottom: 70px;

    .modal-title {
      font-size: 47px !important;
    }

    .modal-right-section {
      .modal-sub-title {
        padding: 5px 0 15px 0;
      }

      label[for='profileImage'] {
        margin-bottom: 15px;
      }
    }

    @media only screen and (max-width: 575px) {
      // background-image: url('~/public/assets/images/m-model-background-5.png') !important;
      background-size: contain;
      min-height: 10px;
      padding-bottom: 30px;

      img.modal-left-img {
        width: 108px;
        padding-top: 30px;
      }

      .modal-right-section {
        .backArrow {
          position: absolute;
          top: -44px;
          left: -35%;
        }

        text-align: left;
        padding-left: 15px;

        input.form-control {
          margin-bottom: 0;
        }

        .error_message {
          bottom: -4px;
        }

        .btn-secondary {
          margin: 0 auto 0 auto;
          display: block;
          background: none;
        }

        .form-check .form-check-label {
          padding-left: 18px;
          padding-right: 10px;
        }
      }
    }

    .modal-body {
      padding: 5px 30px 0 0;
    }
  }

  @media only screen and (max-width: 767px) {
    .tu-common-modal-row {
      display: flex;
      flex-wrap: nowrap;
      text-align: left;
    }
  }

  .modal-body {
    padding: 0;
  }
}

.avtar {
  &-signup {
    position: relative;
    cursor: pointer;
    width: 6rem;
    height: 6rem;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    &::before {
      content: '';
      background-image: url('~/public/assets/images/plus-circle.png');
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: -3px;
      right: 0px;
    }
  }

  &-dropdown {
    position: absolute;
    top: calc(100% - 15px);
    left: calc(100% - 30px);
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    width: 27rem;
    background-color: #f9f9f9;
    z-index: 9;

    @media only screen and (max-width: 490px) {
      width: 15rem;
    }

    img {
      max-width: 5rem;
    }
  }
}

.preferred-language {
  padding-top: 25px;

  ul {
    li {
      list-style: none;
      padding-right: 30px;
      font-size: 18px;
      cursor: pointer;
    }

    @media only screen and (max-width: 575px) {
      padding-left: 0px;
      justify-content: space-between;

      li {
        padding-right: 10px;
        font-size: 13px;
      }
    }
  }
}

.model_top_1,
.model_top_2,
.model_top_3,
.model_top_4 {
  @media only screen and (max-width: 575px) {
    .modal-content {
      max-width: 390px;
      margin: 0 auto;
    }

    .modal-right-section {
      text-align: center;
      padding-left: 0;
      max-width: 330px;
      margin: 0 auto;

      .modal-title {
        font-size: 40px;
      }

      .modal-sub-title {
        font-size: 20px;
      }
    }

    .signup-avtar {
      width: 48px !important;
      height: 48px !important;
    }
  }
}

.model_top_2,
.model_top_3 {
  .modal-dialog {
    @media only screen and (max-width: 575px) {
      .modal-bg {
        background-size: cover;
        padding-bottom: 30px;
        min-height: 10px;
      }

      .modal-content {
        max-width: 390px;
        margin: 0 auto;
      }

      .modal-right-section {
        padding: 0px 0 0 10px;
      }

      .modal-body {
        margin-top: 0;
      }
    }
  }
}

/* info modal */

.vote_now_default,
.toss_now_default,
.no_ball,
.refer-modal,
.profile-success {
  .modal-dialog,
  .modal-bg {
    max-width: 590px;

    @media only screen and (max-width: 575px) {
      max-width: 390px;
    }
  }
}

.toss_now_default {
  .modal-content {
    background-image: url('~/public/assets/images/model-background-error.png');
    padding: 0;
    min-height: 570px;

    @media only screen and (max-width: 575px) {
      background-image: url('~/public/assets/images/m-model-bg-error.png') !important;
    }

    .modal-body {
      padding: 0px 44px 30px 44px;

      @media only screen and (max-width: 575px) {
        padding: 30px;
      }

      @media only screen and (max-width: 370px) {
        padding: 10px;
      }

      h2 {
        padding-bottom: 0rem;
        font-size: 48px;

        @media only screen and (max-width: 575px) {
          font-size: 40px;
        }
      }

      h3 {
        font-size: 48px;

        @media only screen and (max-width: 575px) {
          font-size: 40px;
        }
      }

      h4 {
        font-size: 32px;
        padding-top: 20px;

        @media only screen and (max-width: 575px) {
          font-size: 20px;
        }
      }

      .btn {
        cursor: pointer;

        // &:hover,
        // &:focus,
        // &:active {
        //   color: #fff;
        //   background-color: #b80f2b !important;
        //   border-color: #b80f2b !important;
        // }

        @media only screen and (max-width: 575px) {
          font-size: 24px;
        }
      }

      img {
        margin: 20px 0 40px 0;
        max-height: 171px;
        max-width: 171px;

        @media only screen and (max-width: 575px) {
          max-height: 147px;
          max-width: 147px;
        }
      }
    }
  }
}

.vote_now_default {
  .modal-content {
    background-image: url('~/public/assets/images/model-background-error.png');
    padding: 0;

    @media only screen and (max-width: 575px) {
      background-image: url('~/public/assets/images/m-model-bg-error.png') !important;
    }

    .modal-body {
      padding: 30px 44px 130px 44px;

      @media only screen and (max-width: 370px) {
        padding: 30px 10px 160px 10px;
      }

      h2 {
        padding-bottom: 11rem;
        font-size: 48px;

        @media only screen and (max-width: 575px) {
          font-size: 40px;
        }
      }

      h3 {
        font-size: 48px;
        max-width: 418px;
        margin: 0 auto;

        @media only screen and (max-width: 575px) {
          font-size: 40px;
        }
      }

      h4 {
        font-size: 36px;
        padding-top: 2rem;

        @media only screen and (max-width: 575px) {
          font-size: 24px;
        }
      }
    }
  }
}

//refer modal
.modal-claim-ticket {
  .privacy {
    display: flex;
    max-width: 250px;
    margin: 0 auto;
    align-items: baseline;

    a {
      color: white;
      font-size: 18px;
      text-align: center;
      display: block;
      padding-top: 20px;
      margin: 0 auto;
      position: relative;
      margin-top: -12px;
      text-decoration: underline;
    }
  }
}

.refer-modal {
  .modal-content {
    @media only screen and (max-width: 575px) {
      background-image: url('~/public/assets/images/m-refer-bg.png') !important;
    }

    .modal-body {
      .modal-right-section {
        max-width: 339px;
        padding-left: 0;
        margin: 0 auto;

        @media only screen and (max-width: 575px) {
          text-align: left;
        }

        .form-control:focus {
          border-color: #495057 !important;
        }

        .form-control.is-invalid:focus {
          border-color: $danger !important;
        }

        .modal-title {
          max-width: 250px;
        }

        .claim-head {
          font-size: 40px;
          line-height: 44px;
        }

        p {
          font-size: 13px;
          line-height: 20px;
        }

        .anchor-without-underscore {
          color: white;
          font-size: 20px;
          text-align: center;
          display: block;
          padding-top: 20px;
          margin: 0 auto;
          position: relative;
          margin-top: -12px;

          &:hover {
            text-decoration: none !important;
          }
        }

        .anchor-with-underscore {
          color: white;
          font-size: 20px;
          text-align: center;
          display: block;
          padding-top: 20px;
          margin: 0 auto;
          position: relative;
          margin-top: -12px;

          // text-decoration: underline;
          &:after {
            content: ' ';
            position: absolute;
            width: 30%;
            height: 10px;
            border-bottom: 1px solid white;
            top: 80%;
            margin: 0 auto;
            left: 0;
            right: 0;

            @media only screen and (max-width: 430px) {
              width: 40%;
            }

            @media only screen and (max-width: 350px) {
              width: 50%;
            }
          }

          &:hover {
            text-decoration: none !important;
          }
        }
      }

      img.modal-left-img {
        top: 4rem;

        @media only screen and (max-width: 575px) {
          min-height: 108px;
          max-width: 135px;
          top: 6rem;
        }
      }

      .error_messagesec,
      .error_message {
        @media only screen and (max-width: 348px) {
          top: 110px !important;
        }

        @media only screen and (max-width: 325px) {
          top: 105px !important;
        }
      }

      .error_red {
        @media only screen and (max-width: 400px) {
          bottom: 44px !important;
          line-height: 16px;
        }

        @media only screen and (max-width: 325px) {
          bottom: 28px !important;
        }
      }
    }
  }
}

.modal-invite {
  .modal-content {
    @media only screen and (max-width: 575px) {
      background-image: url('~/public/assets/images/m-invite.png') !important;
    }

    .modal-title {
      font-size: 48px;
      line-height: 48px;

      @media only screen and (max-width: 575px) {
        font-size: 8.6vw;
        line-height: 8.6vw;
      }
    }

    .form-control:focus {
      border-color: #495057 !important;
    }

    .form-control.is-invalid:focus {
      border-color: $danger !important;
    }

    .modal-sub-title {
      margin-right: 60px;

      @media only screen and (max-width: 575px) {
        margin-right: 10px;
      }
    }
  }
}

//no-ball modal

.no_ball {
  .modal-content {
    background-image: url('~/public/assets/images/model-background-error.png');

    @media only screen and (max-width: 575px) {
      background-image: url('~/public/assets/images/m-model-bg-error.png') !important;
    }
  }

  .modal-bg {
    .modal-body {
      padding: 100px 0 80px 0px;
      max-width: 470px;
      margin: 0 auto;

      h2 {
        font-size: 64px;
        line-height: 68px;
        margin-bottom: 58px;

        @media only screen and (max-width: 575px) {
          font-size: 40px;
          line-height: 44px;
        }
      }

      h3 {
        @media only screen and (max-width: 575px) {
          font-size: 24px;
          line-height: 28px;
        }
      }

      h4 {
        font-size: 40px;
        line-height: 48px;

        @media only screen and (max-width: 575px) {
          font-size: 24px;
          line-height: 32px;
        }
      }

      .modal-right-section {
        max-width: 339px;
        padding-left: 0;
        margin: 0 auto;

        @media only screen and (max-width: 575px) {
          text-align: left;
        }

        .modal-title {
          max-width: 200px;
        }
      }

      img.modal-left-img {
        @media only screen and (max-width: 575px) {
          max-height: 108px;
          max-width: 135px;
        }
      }
    }
  }

  &.redeem_no_ball {
    .modal-body {
      padding-bottom: 30px;
    }
  }

  &.redeem-merch {
    .modal-body {
      padding-bottom: 50px;
    }
  }

  &.incorrect-noball {
    .modal-body {
      padding: 170px 0px;

      h2 {
        margin-bottom: 10px;
      }
    }
  }
}

.victory-noball {
  .modal-content {
    padding: 0;
    background-image: url('~/public/assets/images/model-background-success.png') !important;

    @media only screen and (max-width: 575px) {
      background-image: url('~/public/assets/images/m-model-bg-error.png') !important;
    }
  }

  .modal-bg {
    .modal-body {
      padding: 150px 24px;
      max-width: 480px;
      margin: 0 auto;

      h2 {
        font-size: 64px;
        line-height: 68px;
        margin-bottom: 58px;

        @media only screen and (max-width: 575px) {
          font-size: 40px;
          line-height: 44px;
        }
      }

      h4 {
        font-size: 44px;
        line-height: 48px;

        @media only screen and (max-width: 575px) {
          font-size: 20px;
          line-height: 22px;
        }
      }

      .modal-right-section {
        max-width: 339px;
        padding-left: 0;
        margin: 0 auto;

        @media only screen and (max-width: 575px) {
          text-align: left;
        }

        .modal-title {
          max-width: 200px;
        }
      }

      img.modal-left-img {
        @media only screen and (max-width: 575px) {
          max-height: 108px;
          max-width: 135px;
        }
      }
    }
  }
}

// collect-coins modal
.collect-coins {
  .modal {
    &-content {
      background-image: url('~/public/assets/images/Victory-coin-bg.png');
    }

    &-body {
      h2 {
        font-size: 48px;
      }

      h6 {
        font-size: 24px;
      }

      .bottom-txt {
        font-size: 18px;
        line-height: 28px;
        opacity: 70%;
      }

      .victory-wrapper {
        max-width: 700px;
        margin: 0 auto;
        // padding-top: 10px;

        .col {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        h4 {
          font-size: 36px;

          @media only screen and (max-width: 991px) {
            font-size: 24px;
          }
        }
        .thumps-img-pop{
          max-width: 70px;
          width: 100%;
          height: auto;
        }
      }

      img.gold-coin {
        max-width: 463px;
        width: 100%;
        
      }

      img.img-fluid{
        max-width: 80%;
        height: auto;
        padding-bottom: 1rem;
        margin-top: 1rem;
        
      }
.ticket-2x-img{
  width: 100px;
}
      .last-date {
        font-size: 1.4rem;
        opacity: 0.7;
      }

      .match-coin-btns {
        button {
          min-width: 154px;
        }
      }
    }
  }
}

@media (min-width : 991px){
  .ticket-2x-img{
        max-width: 80%;
  }
}

@media only screen and (max-width: 991px) {
  .collect-coins {
    .modal {
      &-content {
        background-image: url('~/public/assets/images/m-collect-coins-bckground.png');
      }

      &-body {
        img.gold-coin {
          max-width: 280px;
          width: 100%;
          // max-height: 44px;
        }

        img.thumps-up {
          max-width: 158px;
        }

        h2 {
          font-size: 28px;
        }

        .display-3,
        h6 {
          font-size: 3.18vw;
          line-height:3.45vw;
          @media (max-width:345.98px) {
            font-size: 3vw;
          }
        }

        .display-2 {
          font-size: 14px;
          line-height: 16px;
        }

        button {
          font-size: 20px;
          margin: 8px;
        }

        .last-date {
          font-size: 1rem;
        }

        .match-coin-btns {
          button {
            min-width: 1px;
            font-size: 1.8rem;
          }
        }

        img.img-fluid{
          max-width: 92%;
          height: auto;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .collect-coins {
    .modal {
      &-body {
        .match-coin-btns {
          button {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .collect-coins {
    .modal {
      &-body {
        .victory-wrapper {
          &.caption {
            .col {
              padding-left: 0;
              padding-right: 0;
            }
          }
        }

        img.img-fluid{
          max-width: 100%;
          height: auto;
        }
      }
    }
  }
}

//profile success modal

.profile-success {
  .modal-content {
    padding: 0;
    background-image: url('~/public/assets/images/model-background-success.png');

    @media only screen and (max-width: 575px) {
      background-image: url('~/public/assets/images/m-profile-bg.png') !important;
    }

    .modal-bg {
      .modal-body {
        // max-width: 347px;
        margin: 0 auto;
        padding: 30px 0px 90px 0;

        h2 {
          font-size: 56px;

          @media only screen and (max-width: 575px) {
            font-size: 44px;
          }
        }

        h3 {
          font-size: 35px;

          @media only screen and (max-width: 575px) {
            font-size: 28px;
          }
        }

        h4 {
          @media only screen and (max-width: 575px) {
            max-width: 295px;
            font-size: 28px;
            margin-left: auto;
            margin-right: auto;
          }
        }

        img {
          margin-bottom: 50px;

          @media only screen and (max-width: 575px) {
            max-height: 175px;
            max-width: 167px;
          }
        }

        button.btn {
          max-width: 347px;
          display: block;
          width: 100%;
          margin: 0 auto;

          @media only screen and (max-width: 575px) {
            max-width: 233px;
            display: block;
            width: 100%;
            margin: 0 auto;
            font-size: 24px;
          }
        }
      }
    }
  }
}

//vote sucess
.vote-success {
  .modal-dialog {
    max-width: 590px;
    margin: 0 auto;

    .modal-content {
      padding: 0;
      background-image: url('~/public/assets/images/model-background-success.png');

      @media only screen and (max-width: 575px) {
        background-image: url('~/public/assets/images/m-profile-bg.png') !important;
      }

      .modal-bg {
        background-repeat: no-repeat;
        background-size: auto;

        .modal-body {
          max-width: 500px;
          margin: 0 auto;
          padding: 70px 0px 80px 0;

          h2 {
            font-size: 48px;

            @media only screen and (max-width: 575px) {
              font-size: 44px;
            }
          }

          h3 {
            font-size: 36px;

            @media only screen and (max-width: 575px) {
              font-size: 28px;
            }
          }

          img {
            margin-bottom: 50px;

            @media only screen and (max-width: 575px) {
              max-height: 175px;
              max-width: 167px;
            }
          }

          button.btn {
            max-width: 347px;
            display: block;
            width: 100%;
            font-size: 32px;
            margin: 0 auto;

            @media only screen and (max-width: 575px) {
              max-width: 233px;
              display: block;
              width: 100%;
              margin: 0 auto;
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}

//wallet sucess
.wallet-success {
  .modal-dialog {
    max-width: 590px;
    margin: 0 auto;

    .modal-content {
      padding: 0;
      background-image: url('~/public/assets/images/model-background-success.png') !important;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 15px;

      @media only screen and (max-width: 575px) {
        max-width: 390px;
        margin: 0 auto;
      }

      @media only screen and (max-width: 575px) {
        background-image: url('~/public/assets/images/m-profile-bg.png') !important;
        background-size: cover;
      }

      .modal-body {
        max-width: 590px;
        margin: 0 auto;
        padding: 10px 20px 60px 20px;

        h2 {
          font-size: 48px;
          padding: 0 60px;

          @media only screen and (max-width: 575px) {
            font-size: 36px;
            padding: 0 10px;
          }
        }

        h3 {
          font-size: 36px;
          // margin-bottom: 0;

          @media only screen and (max-width: 575px) {
            font-size: 24px;
          }
        }

        img {
          margin-bottom: 30px;
          max-width: 227px;
          width: 100%;

          @media only screen and (max-width: 575px) {
            max-height: 175px;
            max-width: 167px;
          }
        }

        button.btn {
          max-width: 347px;
          // display: block;
          width: 100%;
          font-size: 32px;

          @media only screen and (max-width: 575px) {
            max-width: 233px;
            display: block;
            width: 100%;
            margin: 0 auto;
            font-size: 24px;
          }
        }
      }
    }
  }
}

.vote-border-inset-right {
  position: relative;

  &:before {
    background: #359ed0;
    width: 1px;
    height: 126%;
    position: absolute;
    content: '';
    right: -1px;
    top: -14px;

    @media only screen and (max-width: 575px) {
      height: 136%;
    }
  }

  &:after {
    display: block;
    width: 1px;
    height: 126%;
    position: absolute;
    content: '';
    background: #0272b2;
    right: 0;
    top: -14px;

    @media only screen and (max-width: 575px) {
      height: 136%;
    }
  }
}

//redeme sucess
.redeem-success {
  .modal-dialog {
    max-width: 590px;
    margin: 0 auto;

    .modal-content {
      padding: 0;

      background-image: url('~/public/assets/images/model-background-error.png') !important;
      background-repeat: no-repeat;
      background-size: auto;

      @media only screen and (max-width: 575px) {
        background-image: url('~/public/assets/images/m-model-bg-error.png') !important;
      }

      .modal-body {
        // max-width: 347px;
        margin: 0 auto;
        padding: 10px 20px 40px 20px;

        h2 {
          font-size: 64px;
          padding: 0 50px;

          @media only screen and (max-width: 575px) {
            font-size: 44px;
          }
        }

        h3 {
          font-size: 32px;
          // margin-bottom: 0;

          @media only screen and (max-width: 575px) {
            font-size: 28px;
          }

          @media only screen and (max-width: 374px) {
            font-size: 26px;
          }
        }

        h3.victory {
          font-size: 44px;
        }

        h4 {
          font-size: 44px;
          // margin-bottom: 0;

          @media only screen and (max-width: 575px) {
            font-size: 32px;
          }
        }

        img {
          margin-bottom: 50px;

          @media only screen and (max-width: 575px) {
            max-height: 175px;
            max-width: 167px;
          }
        }

        button.btn {
          max-width: 347px;
          display: block;
          width: 100%;
          font-size: 32px;
          margin: 0 auto;

          @media only screen and (max-width: 575px) {
            max-width: 233px;
            display: block;
            width: 100%;
            margin: 0 auto;
            font-size: 24px;
          }
        }
      }
    }
  }
}

.thank-redeem {
  .modal-body {
    padding: 0 0 80px 0 !important;
  }
}

// select dropdown start
.tu-select-wrapper {
  // position: relative;

  select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &::after {
    content: '';
    background: url('~/public/assets/images/dropdown.png');
    background-repeat: no-repeat;
    position: absolute;
    right: 42px;
    width: 16px;
    height: 8px;
    top: 23px;
    background-size: contain;
  }
}

.tu-select {
  .tu-select-wrapper {
    position: relative;
    padding-bottom: 15px;

    .error_message {
      top: 57px;
    }
  }
}

svg.dropdown-heading-dropdown-arrow.gray {
  color: $secondary;
}

span.gray {
  color: #495057 !important;
}

//contact modal
.contact-modal {
  .modal-dialog {
    max-width: 666px !important;
    width: 100%;
  }

  .modal-body {
    padding: 1.8vw 4px 0 4px;

    .modal-title {
      margin-bottom: 4.6vw;
    }

    .mt-4 {
      h4 {
        margin-bottom: 3px;
      }

      .modal-sub-title {
        margin-bottom: 4.4vw;
      }
    }

    .toll-number {
      padding-bottom: 2.5vw;

      h4 {
        margin-bottom: 0;
      }

      .modal-sub-title {
        line-height: 30px;
      }
    }
  }

  .modal-content {
    background-image: url('~/public/assets/images/contact-bg.png') !important;
    background-size: cover;
    min-height: 511px;

    @media only screen and (max-width: 575px) {
      background-image: url('~/public/assets/images/m-model-background-2.png') !important;
      min-height: 380px;
    }
  }
}

// select dropdown end
.multi-select {
  span {
    color: $light;
  }
}

.preference-select {
  .dropdown-content {
    ul {
      padding: 0 !important;
      margin: 0 !important;
    }

    li {
      position: relative;
      padding: 0 !important;
      margin: 0 !important;
    }

    .badge {
      color: $light !important;
      background-color: #00a0e0 !important;
      position: absolute;
      left: 30px;
      top: 13px;
      z-index: 1;
    }
  }

  .item-renderer {
    display: flex !important;
    padding: 0 0 0 30px;
    align-items: center;

    .preference-check {
      position: absolute;
      left: 30px;
      top: 11px;

      label {
        width: 21px;
        height: 21px;
        border: 1px solid #0386c4;
        border-radius: 2px;
        position: absolute;
        left: 0;
        top: 3px;
        margin: 0;

        &:after {
          content: '';
        }
      }

      input {
        position: relative;
        z-index: 1;
        opacity: 0;
      }
    }

    .preference-label {
      color: #00a0e0;
      padding: 4px 0 0 30px;
    }
  }
}

//thank you
label.btn.btn-light {
  width: 100%;
  max-width: 340px;
  margin-bottom: 30px;

  @media only screen and (max-width: 575px) {
    max-width: 200px;
    margin-bottom: 20px;
  }
}

// Forms start here

.form {
  &-group {
    margin-bottom: 15px;

    @media only screen and (max-width: 425px) {
      margin-bottom: 12px;
    }

    // @media only screen and (max-width: 320px) {
    //   margin-bottom: 15px;
    // }
  }

  &-control {
    font-size: 20px;
    line-height: 1;
    padding: 10px;
    height: 55px;
    background: $light;
    border-radius: 12px;

    @media only screen and (max-width: 425px) {
      height: 42px;
    }
  }

  &-label {
    font-size: 20px;
    line-height: 1;

    @media only screen and (max-width: 425px) {
      font-size: calc(1.325rem + 0.9vw);
    }
  }
}

.invalid-feedback,
.error_message {
  font-size: 18px;
  line-height: 1.2;

  // margin-top: 0.4rem;
  @media only screen and (max-width: 575px) {
    font-size: calc(1.325rem + 0.9vw);
  }
}

.profile-image {
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;

  .avatar-wrapper {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
    border-radius: 50%;

    &::before {
      content: '';
      background-image: url('~/public/assets/images/plus-circle.png');
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  .avatar-dropdown {
    z-index: 1;
  }

  img {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
    border-radius: 50%;
  }

  .error_message {
    color: $light;
    margin-top: '2px';
  }
}

//modal form style end
.contact-modal {
  .modal-content {
    .modal-left-img {
      position: absolute;
      left: -22px;
    }
  }
}
