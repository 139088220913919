.modal {
  &-dialog {}

  &-content {
    border-radius: 20px;
    background-repeat: no-repeat;
    border-radius: 15px;
    background-size: cover;
    background-color: $secondary;
    background-position: top center;
    padding: 15px 5px 20px 5px;
  }

  &-header {
    border: none;
    justify-content: flex-end;
    padding-top: 16px;
    padding-right: 22px;
  }

  &-title {
    font-size: 48px;
    font-weight: 700;
    line-height: 45px;
    margin-bottom: 8px;
  }

  &-sub-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
  }

  &-body {
    padding: 10px 4px 0 4px;
  }

  &-footer {
    border: none;
  }

  &-sm {
    max-width: 590px;
  }

  &-md {
    max-width: 590px;
  }

  &-lg {}

  &-xl {}

  &-backdrop {}

  &-bg {
    &-1 {
      background-image: url("~/public/assets/images/model-background-1.png");
    }

    &-2 {
      background-image: url("~/public/assets/images/model-background-2.png");
    }

    &-3 {
      background-image: url("~/public/assets/images/model-background-3.png");
    }

    &-4 {
      background-image: url("~/public/assets/images/model-background-5.png");
    }
  }

  &-wallet {
    .modal-dialog {
      max-width: 732px;
      width: calc(100% - 30px);
      margin: 30px auto;
    }

    .card {
      background: #0386c4;
      position: relative;
      border-radius: 12px;

      &-match-coins {
        img {
          max-height: 80px;
        }
      }
    }

    .clickable-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      .btn-light {
        color: #0386C4 !important;
        font-size: 20px;
        width: 16px;
        height: 16px;
        border-radius: 4px;
        padding: 0;
        margin: 0;
        line-height: 0;
        text-align: center;
        visibility: hidden;
        &.active{
          visibility:visible;
        }
      }
    }

    .redeem-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .merch-label-red {
        border-radius: 6px;
        border: 1px solid #D62132;
        background: #FFF;
        width: 42px;
        height: 42px;
        color: #00A0E0;
        font-size: 20px;
        text-align: center;
        padding-top: 6px;
      }
      .merch-label {
        border-radius: 6px;
        background: #FFF;
        width: 42px;
        height: 42px;
        color: #00A0E0;
        font-size: 20px;
        text-align: center;
        padding-top: 6px;
      }
    }


    .block {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    .img {
      &-claim {
        max-width: 75px;
        width: 100%;
        height: 70px;
        object-fit: contain;
        @media (max-width:767.98px) {
         max-width: 67px;
         height: 59px; 
        }
      }

      &-victory {
        max-width: 76px;
        width: 100%;
        filter: opacity(0.5);

        &.active {
          filter: opacity(1);
        }

        &.glow {
          filter: drop-shadow(2px 0px 10px yellow);
        }
      }
    }

    .tool-tip {
      background-color: #002169 !important;
    }

    .caption-title {
      font-size: calc(100% + 0.9vw);
    }

    .count {
      margin: 1rem auto;

      &:has(.checkbox-wrapper) {
        padding-right: 17px;
      }
    }

    .claim-box {
      filter: opacity(0.5);
      pointer-events: none;

      &.active {
        filter: opacity(1);
        pointer-events: initial;
      }
    }

    .checkbox-wrapper {
      position: relative;

      pre {
        margin: 0;
        position: absolute;
        right: -20px;
        overflow: hidden;
        top: 4px;

        input {
          position: absolute;
          top: 32%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 20px;
          opacity: 0;
          height: 20px;
        }

        label {
          width: 17px;
          height: 17px;
          border: 1px solid #fff;
          border-radius: 2px;

          &:after {
            content: "";
          }
        }

        input[type="radio"]:checked+label {
          background: #fff;
        }

        input[type="radio"]:checked+label:after {
          box-shadow: -2px 2px 0 #0386c4;
          width: 10px;
          height: 7px;
          transition: 0.5s;
          transform: rotate(-45deg);
          display: block;
          position: relative;
          left: 3px;
        }
      }
    }
  }
}
.claim-box-active{
  filter: drop-shadow(0px 0px 15px #F5CC8E) !important;
}
// @include media-breakpoint-up(lg) {
// }

/* Styles for screens 1200px and larger (extra large) */
@media (min-width: 1200px) {
  /* Your CSS rules here */
}

/* Styles for screens between 992px and 1199.98px (large) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* Your CSS rules here */
}

/* Styles for screens between 768px and 991.98px (medium) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* Your CSS rules here */
}

/* Styles for screens between 576px and 767.98px (small) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* Your CSS rules here */
}

@media (max-width: 575.98px) {

  /* Your CSS rules here */
  .modal {
    &-wallet {
      .vote-now-btn {
        font-size: calc(100% + 0.6vw);
        padding: 0.9rem 1rem;
      }

      .unique-code-btn {
        font-size: calc(100% + 0.6vw);
      }

      .img {
        &-victory {
          max-width: 51px;
        }
      }

      .checkbox-wrapper {
        pre {
          top: 0;
        }
      }
    }
  }
}

@media (max-width: 375.98px) {
  .modal {
    &-wallet {
      .img {
        &-victory {
          max-width: 42px;
        }
      }
    }
  }
}
a:hover{
  color: #fff;
}
// @media (max-width: 325px) {
//   .modal {
//     &-wallet {
//       .vote-now-btn {
//         padding: 0.5rem 1rem;
//       }
//     }
//   }
// }

a {
  color: white;
}
