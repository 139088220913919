//sidebar
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 0px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  border-radius: 0px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #333;
}

.offcanvas-menu {
  width: 320px;
  max-width: 90%;
  transition: transform 0.2s ease-in-out;
  transform: translateX(-100%);
  position: fixed;
  height: 100vh;
  background: $primary;
  left: 0;
  top: 64px;
  z-index: 16;
  padding-top: 50px;

  @media (max-height:1000px) {
    overflow-y: auto;
    padding-bottom: 110px;
  }

  &.show {
    transform: translateX(0);
    @media (max-width:767.98px) {
      top: 94px;
    }
  }
  @media only screen and (max-width: 330px) {
    max-width: 90% !important;
  }
}

.sidebar-main {

  .navbar {
    display: block;
    padding: 0;

    .navbar-toggler {
      padding: 0;
      margin-right: 60px;
      @media (max-width:992.98px) {
        margin-right: 20px;
      }

      &.collapsed {
        &::after {
          display: none;
        }
      }

      &::after {
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top: 64px;
        position: fixed;
        background: rgba(51, 77, 135, 0.7);
        // background: #002169;
        z-index: 1;
        @media (max-width:767px) {
          top: 98px;
        }
      }

      &:focus-visible {
        outline: none !important;
      }

      @media (max-width:767px) {
        margin-right: 10px;
      }
    }
  }
}

button.navbar-toggler {
  border: none;

  &.collapsed {
    .navbar-toggler-icon {
      background-image: url('~/public/assets/images/toggle.png');
    }
  }
}

.navbar-light .navbar-toggler-icon {
  width: 31px;
  height: 22px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('~/public/assets/images/toggle-cross.png');
}

//Feature

.tu-sidebar-list-main {
  &-scroll {
    overflow-y: scroll;
  }

  &-scroll::-webkit-scrollbar {
    display: none;
  }
  
  ul {
    padding-left: 42px;
    margin-bottom: 22px;

    li {
      background: none;
      border: none;
      padding: 0;
      list-style: none;
      cursor: pointer;

      .tu-side-icon {
        display: inline-block;
        width: 30px;
      }

      a {
        font-family: 'Thums Up';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 32px;
        margin-left: 10px;
        vertical-align: middle;
        color: $light;
        text-decoration: none;
      }
    }

    li.last-list-item {
      a {
        line-height: 28px;

        span {
          display: block;
          margin-left: 40px;
          font-size: 18px;
        }
      }
    }
  }
}

//Buy Now
.tu-sidebar-buy-main {
  padding-left: 42px;

  h2 {
    font-family: 'Thums Up';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    color: $light;
  }

  .brand-items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-right: 85px;

    img {
      display: block;
      width: 40px;
      cursor: pointer;
    }

    a {
      margin-bottom: 22px;
      margin-right: 3px;
    }
  }
}

.tu-language{
  &-select-side{
    border: none;
    width: 92%;
    font-family: 'Thums Up';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    color: white;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    margin-left: -4px;
    border-radius: 4px;
    background: transparent;
    line-height: 1.4;
    cursor: pointer;
    appearance: none;
    z-index: 10;
    position: relative;

    &:focus,
    &:active {
      box-shadow: none !important;
      outline: none;
      border: none;
      background: none !important;
    }
  }
  &::after {
    position: absolute;
    content: "";
    background-image: url('~/public/assets/images/white-dropdown.png');
    right: 30px;
    top: 6px;
    background-repeat: no-repeat;
    background-size: contain;
    width: 17px;
    height: 17px;
    @media (max-width:767.98px) {
      height: 12px;
      width: 12px;
    }
   
  }
}
// .tu-language-select-side {
//   // background-color: transparent;
//   border: none;
//   font-family: 'Thums Up';
//   font-style: normal;
//   font-weight: 400;
//   font-size: 22px;
//   color: white;
//   text-align: left;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 0px;
//   margin-left: -4px;
//   border-radius: 4px;
//   background: transparent;
//   line-height: 1.4;
//   cursor: pointer;
//   appearance: none;

//   // &::after {
//   //   position: absolute;
//   //   content: '\25BC';
//   //   font-size: 35px;
//   // }
//   &::after {
//     position: absolute;
//     content: "";
//     border-bottom: 1px solid #ffffff;
//     width: calc(85% - 22px);
//     background-color: #ffffff;
//     height: 1px;
//     margin-right: 22px;
//     bottom: 0;
//     left: 43px;
//   }

//   &:focus,
//   &:active {
//     box-shadow: none !important;
//     outline: none;
//     border: none;
//     background: none !important;
//   }

//   // @media (max-width:767px) {
//   //   font-size: 16px;
//   // }
// }



.tu-language-side-bottom-bar{
  position: relative;
  padding-bottom: 20px !important;

  &::after {
    position: absolute;
    content: "";
    border-bottom: 1px solid #ffffff;
    width: calc(85% - 22px);
    background-color: #ffffff;
    height: 1px;
    margin-right: 22px;
    bottom: 0;
    left: 43px;
  }
}

//Privacy policy
.tu-sidebar-privacy-main {
  padding-left: 42px;
  margin-top: 22px;

  .tu-language-select select {
    width: calc(100% - 140px);
    color: $light;
    background-color: transparent;
    margin-top: 22px;
    margin-left: -4px;
    font-weight: 400;
    font-size: 22px;

    option {
      color: $dark;
    }
  }

  &.tu-privacy {
    ul {
      a {
        margin-left: 0;
      }
    }
  }

  .tu-change-lang {
    margin-top: 22px;

    .dropdown-menu {
      top: 38px;
      width: calc(100% - 54px);

      .dropdown-item {
        font-size: 22px;
        padding: 3px 10px;
      }
    }
  }

  ul {
    margin-top: 18px;
  }
}

.tu-privacy {
  ul {
    li {
      a {
        margin-left: 0;
      }
    }
  }

  .btn-sm {
    padding: 0.9rem 3rem;
  }
}

//common sidebar bottom border
.list-bottom-border {
  position: relative;
  padding-bottom: 20px !important;

  &::after {
    position: absolute;
    content: '';
    border-bottom: 1px solid $light;
    width: calc(100% - 22px);
    background-color: $light;
    height: 1px;
    margin-right: 22px;
    bottom: 0;
    left: 0;
  }
}

@media (max-width:340px) {
  .tu-sidebar-list-main {
    ul {
      li {
        a {
          font-size: 18px;
        }
      }
    }

  }

  .tu-sidebar-privacy-main {
    .logout-btn {
      padding: .5rem 2rem;
      font-size: 2.5rem !important;

    }
  }
}

@media (min-width: 965px) {
  .tu-sidebar-privacy-main {
    .logout-btn {
      padding: 1.5rem 2.5rem !important;
      font-size: 3.1rem !important;

    }
  }
}

body:has(.offcanvas-menu.navbar-collapse.collapse.show){
  overflow:hidden;
  }

  .tu-language-select-side {
    option {
        color: $light;
        background-color: #62171e;
        padding: 1rem;
        border-radius: 8px;

        &:hover {
            color: #1e57c8;
        }
    }
}