$body-bg: #ffffff;
$map-height: 100vh;
$container-height: 100%;

.believo-meter-section {
  background-image: url('~/public/assets/images/background-meter.png');
  background-size: cover;
  background-position: center;
}

.meter {
  width: 100%;

  &-wrapper {
    .fanpulse {
      margin-top: 4rem;
    }
  }

  &-title {
    margin: 0;
    padding: 40px 0 0 0;
    color: #009edb;
    font-size: 44px;
    font-weight: 400;
    line-height: 30px;
  }

  &-heading {
    margin: 0;
    padding: 0;
    font-size: 64px;
    font-weight: 400;
    line-height: 64px;
  }

  &-sub-heading {
    margin: 0;
    padding: 0;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
  }

  &-list {
    list-style-type: none;
    padding-left: 0;
    border: none;
    overflow-x: auto;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;

    /* Internet Explorer 10+ */
    &::-webkit-scrollbar {
      display: none;
    }

    li {
      padding: 10px;
      .city-text{
        opacity: 0;
        font-size: 16px;
      }
    }
    .city{
      .city-text{
        opacity: 1;
        font-size: 16px;
      }
    }

    &:hover {
      border: none;
    }
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    height: 0px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

.my-progress-bar .CircularProgressbar {
  margin: 0 !important;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #205098 !important;
}

.my-progress-bar .CircularProgressbar .CircularProgressbar-text {
  font-size: 10px;
  /* Change the font size */
  font-weight: bold;
  /* Change the font weight */
  /* Add or modify any other desired properties */
}

.progress-bar-wrapper {
  position: relative;
  width: 109px;
  text-align: center;
}

.progress-bar-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 17px;
  line-height: normal;
}

.progress-bar-text span {
  font-size: 16px;
}

.graph-wrapper {
  .line-data {
    canvas {
      display: flex !important;
      width: 100%;
      max-width: 400px;
      height: 256px;
    }
  }
}

// Map section start here

.mapSection {
  background: url('~/public/assets/images/map-background.png') no-repeat bottom center;
  background-size: cover;
  padding: 30px 0;

  &-left {
    max-width: 750px;
    width: 100%;
    height: 720px;
    position: relative;
    margin: 0 auto;
  }

  &-title {
    text-align: right;
    float: right;
    margin: 0;
    padding: 9.5rem 8rem 0 0;
    font-size: 3.5rem;
  }

  &-right {
    width: 550px;
    padding: 100px 0 0 0px;
    margin: 0 auto;

    .heading {
      margin: 0;
      padding: 0;
      line-height: 58px;
      font-size: 52px;
      @media (max-width: 576px) {
        font-size: 40px;
      }
    }
    .sub-heading {
      margin: 0;
      padding: 0;
      line-height: 28px;
      font-size: 24px;
    }
    @media (max-width: 991px) {
      margin-left: auto;
      margin-right: auto;
      padding-top: 0px;
      padding-bottom: 40px;
    }

    @media (max-width: 575px) {
      width: 300px;
    }
  }
}

.leaflet {
  &-container {
    height: 100%;
    background-color: transparent !important;
    outline: unset !important;
    overflow-x: hidden;
    z-index: 1;
  }

  &-image-layer {
    margin: -2.8rem 0 0 8px;
  }


  &-popup-close-button {
    display: none;
  }

  &-popup-content-wrapper {
    background: transparent !important;
  }

  &-popup-content {
    margin: 0 !important;

    .map-popup {
      display: flex;

      .popup-pulse {
        background-color: #cf152d;
        border-radius: 12px 0 0 12px;
        padding: 0 5px;
        display: flex;
        align-items: center;
        h3 {
          font-size: 24px;
          color: $body-bg;
          margin-bottom: 0;
        }
      }
      .popup-title {
        background: #fff;
        border-radius: 0 12px 12px 0;
        padding: 7px 9px;

        h3 {
          font-size: 18px;
          color: #cf152d;
          margin-bottom: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 1225px) {
  .graph-wrapper {
    .line-data {
      canvas {
        display: flex !important;
        max-width: 100% !important;
        padding: 0 40px;
        margin: 0 auto;
        height: auto !important;
        width: calc(100% - 20%) !important;
      }
    }
  }
}
@media only screen and (min-width: 992px) and (max-width: 1190.98px){
  .leaflet-container{
    zoom: 0.7;
  }
}
@media only screen and (max-width: 992px) {
  .mapSection {
    .d-flex {
      display: block !important;
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .believo-meter-section {
    margin-top: -1rem;
    background-image: url('~/public/assets/images/background-meter.png');
    background-size: cover;
    background-position: center;
  }
  .meter {
    &-wrapper {
      margin-top: 10px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: -20px;
      padding: 0 15px;
      max-width: 300px;
    }
    &-title {
      font-size: 28px;
      line-height: 25px;
    }
    &-heading {
      font-size: 44px;
      line-height: 44px;
    }
    &-sub-heading {
      font-size: 20px;
      line-height: 25px;
    }

    &-list {
      margin: 0 auto;

      &:hover {
        border: none;
      }
    }
  }
  .mapSection {
    &-title {
      padding: 0;
      position: absolute;
      left: 58%;
      top: 18%;
    }
    &-left {
      height: 450px;
    }
  }
  .leaflet {
    &-container {
      zoom: 0.5;
      transform: translateX(-50%);
      transform-origin: top left;
      left: 50%;
      overflow: visible;
    }
  }

  .mapSection-right{
    .sub-heading{
      font-size: 20px;
      line-height: 24px;
    }
  }
}

@media only screen and (max-width: 430px) {
  .mapSection {
    &-title {
      left: 52%;
      top: 7%;
      font-size: 1.8rem;
    }

    &-left {
      height: 300px;
    }
  }

  .leaflet {
    &-container {
      zoom: 0.4;
    }
  }
}


// @media (max-width: 767.98px) {
//   .meter-wrapper {
//   padding-top: 20px;
//   }
// }


// @supports (-webkit-overflow-scrolling: touch) {
//   /* CSS specific to iOS devices */ 
//   .image-layer {
//     margin: -1rem 0 0 8px;
//   }
// }


@media only screen and (-webkit-min-device-pixel-ratio: 3), only screen and (min--moz-device-pixel-ratio: 3),
 only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (min-device-pixel-ratio: 3) 
  {
    .leaflet-image-layer {
    margin: -1.1rem 0 0 8px;
  }
  .leaflet-popup-content{
    .map-popup .popup-title h3{
      font-size: 12px;
    }
  }
}