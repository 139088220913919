.bn_global {
  .tu-wallet-link {
    font-size: 16px !important;
    margin-right: 30px;

    @media (max-width: 810px) {
      margin-right: 30px;
      font-size: 11px !important;
    }

    @media (max-width: 415px) {
      margin-right: 10px;
    }

    @media (max-width: 372px) {
      margin-right: 5px;
      font-size: 2.5vw !important;
    }

    @media (max-width: 330px) {
      font-size: 2.2vw !important;
    }
  }
  .card-block-how-to-win-merch-section{
  padding: 1.2rem 3.6rem;
    min-height: unset;
    @media (max-width:767px) {
      padding: 1vw 2.2vw !important;
    }
  }
  @media (max-width:767.98px) {
     .banner h1.section-title-main-home{
    font-size: 5.78vw !important;
    line-height: 7vw !important;
  }
  }
 
  //common
  .section {
    &-desc {
      line-height: 1.5;

      @media (max-width: 767px) {
        font-size: 3vw;
      }
    }

    &-title {
      line-height: 1.2;
      font-size: 4rem;

      &-two {
        font-size: 5rem !important;
      }

      @media (max-width: 767.98px) {
        font-size: 5.8vw !important;
      }
    }
  }

  .tu-language-select-side {
    font-size: 16px;

    @media (max-width: 575.98px) {
      font-size: 12px;
    }
  }

  .tu-language {
    &::after {
      top: 4px;
    }
  }

  .header {
    .tu-language-select {
      &::after {
        right: -0.5vw !important;

        @media (max-width: 767.67px) {
          right: -1vw !important;
        }
      }
    }
  }

  .card-block-how-to-win-merch-section {
    @media (max-width: 500px) {
      padding: 1rem 0.1rem !important;
    }
  }

  .form-label {
    line-height: 1.4;
    font-size: 18px;
  }

  .invalid-feedback,
  .error_message {
    line-height: 1.4 !important;
    // padding-top: 3rem;
    font-size: 2rem;
    // padding-left: 2.7rem;

    @media (max-width: 576px) {
      padding-left: 0;
      font-size: 1.3rem;
      padding-top: 1rem;
    }
  }

  .btn {
    &-sm {
      font-size: 2rem;
    }
  }

  .tu-sidebar-list-main {
    li {
      a {
        font-size: 14px;

        @media only screen and (max-width: 345px) {
          font-size: 12px;
        }

        span {
          font-size: 10px !important;
        }
      }
    }
  }

  .logoutbtn {
    font-size: 2rem !important;
  }

  .tu-profile-dropdown {
    .tu-input-wrapper {
      line-height: 3;
      width: calc(100% - 40px);

      span {
        font-size: 13px;
      }

      input {
        font-size: 20px !important;
      }
    }

    p {
      font-size: 14px !important;
      line-height: 1.8rem;
    }

    .error_message {
      font-size: 13px !important;
      line-height: 2 !important;
    }
  }

  .tu-content-wrapper {
    .tu-country-select {
      span {
        font-size: 20px;
      }
    }
  }

  @media (max-width: 576.98px) {
    img.fanpulse {
      &-home {
        padding: 0rem 0;
      }
    }

    .tu-middle-section {
      .tu-content-wrapper {
        margin: 0 auto 0 auto;
      }
    }
  }

  //header section
  .header {
    .tu-unique-code-wrapper {
      input {
        // padding: 2px 0 0 9px;
        font-size: 15px;
        // width: 164px;
      }

      .btn {
        font-size: 1.2rem;
        padding: 8px;
      }

      @media (max-width: 830px) {
        margin-right: 20px;

        input {
          font-size: 12px;
          // width: 136px !important;
        }

        .btn {
          font-size: 1rem;
          padding: 8px;
        }
      }

      // @media(max-width:810px) {
      //     margin-right: 30px;

      //     .btn,
      //     input {
      //         font-size: 12px;
      //     }

      //     // input {
      //     //     width: 142px;
      //     // }
      // }

      @media (max-width: 415px) {
        margin-right: 10px;
        margin-left: 9px;
      }

      @media (max-width: 372px) {
        margin-left: 0;
        margin-right: 5px;
      }

      @media (max-width: 330px) {

        .btn,
        input {
          font-size: 2.5vw !important;
        }
      }
    }

    &-top {
      .container-fluid {
        @media only screen and (max-width: 767.98px) {
          min-height: 34px;
        }
      }

      .tu-language-select {
        span {
          font-size: 17px;

          @media only screen and (max-width: 767.98px) {
            font-size: 12px;
          }
        }
      }
    }

    @media only screen and (max-width: 767.67px) {
      .form-label {
        font-size: 15px;
      }

      .tu-unique-code-wrapper {
        .btn {
          padding: 5px 5px;
          font-size: 10px;

          text-wrap: nowrap;

          @media (max-width: 370px) {
            font-size: 9px;
          }
        }

        input {
          // width: 105px;
          font-size: 12px;
        }
      }
    }
  }

  //fixes on howToWin ctas

  @media (min-width: 992px) and (max-width: 1110px) {
    .card {
      &-block {
        &-sm {
          .card {
            &-block {
              &-img-top {
                max-width: 10.5rem;
              }
            }
          }
        }
      }
    }

    .btn-sm {

      &-ticket,
      &-merch {
        margin-bottom: 1rem;
        font-size: 1.5rem;
        line-height: 1.4;
        min-height: 4.6vh;
      }

      &-merch {
        padding-top: 1.4vh;
        padding-bottom: 1rem;
      }
    }
  }

  @media (max-width: 767px) {
    .card {
      &-block {
        &-how-to-win-merch {
          min-height: 4vh;
        }
      }
    }

    .btn-sm {
      &-ticket {
        line-height: 1.2;
      }
    }
  }

  @media (max-width: 500px) {
    .card {
      &-block {
        &-how-to-win-merch {
          min-height: 4vh !important;
          height: 52px;
        }
      }
    }
  }

  //banner section
  .banner {
    &-content {
      &-body {
        @media (min-width: 767px) {
          max-width: 45vw;
        }
      }
    }

    @media (min-width: 2060px) {
      &-content {
        &-body {
          // max-width: 19vw;
        }
      }

      .section {
        &-sub-title {
          font-size: 4rem;
        }
      }
    }

    @media (min-width: 1728px) {
      .section {
        &-sub-title {
          padding: 0 1rem;
          font-size: 3.8rem;
        }
      }
    }

    @media (min-width: 1600px) and (max-width: 1727px) {
      .section {
        &-sub-title {
          font-size: 3rem;
        }
      }
    }

    @media (min-width: 1493.98px) and (max-width: 2060px) {
      &-content {
        &-body {
          max-width: 36vw;
        }
      }

      .section {
        &-title {
          font-size: 3.7rem;
        }
      }
    }

    @media (min-width: 1310.98px) and (max-width: 1493px) {
      &-content {
        &-body {
          //   max-width: 24vw;
        }
      }

      .section {
        &-sub-title {
          //   font-size: 3.4rem;
        }
      }
    }

    @media (min-width: 1200px) and (max-width: 1310px) {
      .section {
        &-title {
          font-size: 3.5rem;
          line-height: 1.2;
        }

        &-sub-title {
          .text-large {
            font-size: 3vw;
          }

          font-size: 2.9rem;
          padding: 0 3rem;
        }
      }
    }

    @media (max-width: 1199.98px) {
      .section {
        &-title {
          margin-left: -2rem;
          font-size: 3vw;
          line-height: 3.5vw;
        }

        &-sub-title {
          .text-large {
            font-size: 3vw;
          }

          font-size: 2.2vw;
          padding: 0 5rem;
        }
      }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      .section {
        &-title {
          &-howToWin {
            margin-top: 5rem;
          }
        }
      }
    }

    @media (min-width: 768px) and (max-width: 968px) {
      .section {
        &-title {
          margin-left: -2rem;
        }

        &-sub-title {
          .text-large {
            font-size: 3.2vw;
          }

          font-size: 2.2vw;
          line-height: 1;
          padding: 0 5rem;
        }
      }
    }

    @media only screen and (max-width: 767.98px) {
      &-content {
        &-body {
          // max-width: 33vw;
        }
      }
    
      .section {
        &-title {
          font-size: 3.8vw !important;
          line-height: 5.3vw !important;
          margin-left: 0rem;
        }
&-title-hotstar{
 
  margin-bottom: -2vh !important;
  font-size: 3.7vh !important;
}
        &-sub-title {
          .text-large {
            font-size: 4.5vw;
          }

          padding: 0 2rem;
          font-size: 4vw;
        }
      }
    }

    @media only screen and (max-width: 575.98px) {
      .section {
        &-title {
          line-height: 0.8  !important;
          margin-left: 0rem;
        }

        &-sub-title {
          .text-large {
            font-size: 3.5vw;
          }

          font-size: 3vw;
          padding: 0 2.5rem;
        }
      }

      &-img {
        max-height: 28rem;
        object-fit: cover;
        object-position: bottom;
      }

      .object {
        object-position: top;
      }
    }

    @media only screen and (max-width: 325px) {
      .section {
        &-title {
          margin-left: 0rem;
          line-height: 5vw;
        }

        &-sub-title {
          padding: 0 1rem;
        }
      }

      .card-block-how-to-win-merch {
        min-height: 52px;
      }
    }
  }

  //middle section
  @media only screen and (max-width: 767.98px) {
    .tu-content-wrapper {
      .btn {
        font-size: 1.6rem;
      }
    }
  }

  //footer section
  @media only screen and (max-width: 991.98px) {
    .footer {
      p {
        font-size: 2.5vw;
      }
    }
  }

  //how to win section
  @media (max-width: 500px) {
    .card {
      &-block {
        .btn {
          &-primary {
            font-size: 3vw;
            line-height: 18px;
          }
        }
      }
    }
.ticket-win{
  padding-bottom: 1.29vw !important;
  min-height: 4vh !important;
  height: 52px;
}
    .section {
      &-title {
        font-size: 9vw;
      }
    }

    .btn {
      font-size: 2.1vw;
    }
  }

  //meter section
  @media only screen and (max-width: 767.98px) {
    .meter-sub-heading {
      font-size: 16px;
    }

    .progress-bar-text {
      span {
        font-size: 1.4rem;
      }
    }
  }

  //contest section
  .contest {
    .section {
      &-title {
        @media only screen and (max-width: 767.98px) {
          font-size: 4vw !important;
          line-height: 5vw !important;
        }
      }
    }

    &-btn {
      &-bn {
        @media (max-width: 1199px) {
          font-size: 2.2vw !important;
        }

        @media (max-width: 550px) {
          font-size: 3vw !important;
          line-height: 5vw !important;
          height: 12vw;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        font-size: 1.5vw !important;
        line-height: 33px !important;
      }

      font-size: 3.5vw !important;
      line-height: 22px !important;
    }
  }

  #how_to_win {
    @media only screen and (min-width: 767.98px) {
      .btn {
        font-size: 2.4rem;
      }
    }
  }

  //popup section
  .profile-success,
  .vote_now_default,
  .toss_now_default {
    .modal {
      &-body {
        @media only screen and (max-width: 575px) {
          h4 {
            font-size: 2.1rem !important;
            padding-top: 2rem !important;
          }

          h3 {
            font-size: 28px !important;
          }

          h2 {
            font-size: 32px !important;
          }
        }
      }
    }
  }

  .profile-success {
    .modal {
      &-body {
        h4 {
          font-size: 2.3rem;
          padding: 0 2rem;
        }
      }
    }
  }

  .redeem-hi {
    .modal-body {

      h4,
      h3.victory {
        font-size: 30px !important;
        line-height: 1.4;

        @media only screen and (max-width: 575px) {
          font-size: 28px !important;
        }

        @media only screen and (max-width: 430px) {
          font-size: 26px !important;
        }
      }

      @media only screen and (max-width: 430px) {
        h2 {
          font-size: 26px !important;
        }
      }
    }
  }

  .modal-claim-ticket {
    select.form-control {
      line-height: 2;

      @media (max-width: 425.98px) {
        height: 55px;
      }
    }
  }

  /*---------------------------------------------------new css------------------------------------------------------*/
  //popup section
  .modal {
    &-title {
      font-size: 3.6rem !important;
    }

    h6 {
      line-height: 1.4;
    }

    &-sub-title {
      font-size: 1.4rem;
      line-height: 1.4;
    }

    &-wallet .caption-title {
      font-size: 1.3rem;
    }

    &-bg1,
    &-bg2,
    &-bg3,
    &-bg4 {
      .modal {
        &-title {
          font-size: 3.6rem;
          line-height: 1.4 !important;

          @media (max-width: 500px) {
            font-size: 5.4vw!important;
          }
        }

        &-sub-title {
          font-size: 1.3rem;
          line-height: 1.4;

        }

        &-content {
          .form-check-label {
            line-height: 1.5;
            font-size: 15px;
          }
        }
      }
    }

    .btn {
      font-size: 1.4rem;
      line-height: 1.3;
    }
  }

  .collect-coins {
    .modal-body {
      h2 {
        font-size: 1.9rem;
        line-height: 1.4;
      }

      .victory-wrapper {
        h4 {
          font-size: 3rem;
          line-height: 1.4;
        }
      }
    }
  }

  .display {
    &-3 {
      font-size: 1.1rem;
      line-height: 1.4;
    }
  }

  @media (max-width: 991px) {
    .collect-coins {
      .modal-body {

        .display-3,
        h6 {
          line-height: 1.5;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .modal {
      &-title {
        font-size: 2.4rem !important;
      }

      &-sub-title {
        font-size: 3.9vw;
      }

      .btn {
        font-size: 1.3rem;
        margin-top: 10px !important;
      }

      &-wallet {
        .caption-title {
          font-size: 1.5vw;
        }
      }

      &-invite,
      &-claim-ticket {
        .modal-title {
          font-size: 2.4rem;
          line-height: 1.4;
        }

        .modal-sub-title {
          font-size: 1.4rem;
          line-height: 1.4;
        }
      }

      &-claim-ticket {
        .modal-body {
          .modal-right-section {
            h2.claim-head {
              font-size: 2rem;
              line-height: 1.4;
            }
          }
        }

        .mandatory-text {
          font-size: 22px;
        }
      }
    }

    .collect-coins {
      .modal-body {

        .display-3,
        h6 {
          font-size: 0.9rem;
          line-height: 1.8;
        }

        h2 {
          font-size: 1.4rem;
        }

        .victory-wrapper {
          h4 {
            font-size: 2rem;
          }
        }

        button {
          font-size: 1.6rem;

          @media (max-width: 576.98px) {
            font-size: 1.2rem;
            line-height: 1.4;
            height: 51px;
          }
        }
      }
    }
  }

  @media (max-width: 576px) {
    .modal {
      &-wallet .caption-title {
        height: 17px;
        font-size: 11px;
        line-height: 1.5;
        margin-bottom: 2rem !important;
        max-width: 170px;
        margin: 0 auto;
      }
    }

    .form {
      &-label {
        font-size: 10px;
      }
    }
  }

  @media (max-width: 380px) {
    .modal {
      &-wallet {
        .btn {
          font-size: 1rem;
          line-height: 1.6;
        }
      }
    }

    .collect-coins {
      .modal-body {
        button {
          font-size: 1.2rem;
        }
      }
    }
  }

  @media (max-width: 400px) {
    .modal {
      &-wallet .caption-title {
        margin-bottom: 4rem !important;
      }
    }
  }

  @media (max-width: 327.9px) {
    .collect-coins {
      .modal-body {
        button {
          font-size: 1rem;
        }
      }
    }
  }

  .error_message {
    line-height: 2;
  }

  .tu-worldcup-section {
    padding-bottom: 2.5vw;
  }

  .modal-invite {
    .modal {
      &-title {
        font-size: 24px;
      }
    }
  }

  @media (max-width: 576px) {
    .modal {
      &-wallet {
        .btn {
          height: 57px;
          font-size: 11px !important;
        
          padding: 5px;

        }
        .claim-btn{
            margin: 0 10px;
        }
      }
    }
  }

  .refer-modal {
    .modal {
      &-right-section {
        .anchor-with-underscore {
          &::after {
            width: 45% !important;

            @media (max-width: 575.9px) {
              width: 35% !important;
            }

            @media (max-width: 375.9px) {
              width: 60% !important;
            }
          }

          @media (max-width: 575.9px) {
            font-size: 14px !important;
          }
        }
      }
    }
  }

  .progress-bar-text {
    span {
      line-height: 1;
    }
  }

  .logout-btn {
    @media (max-width: 855px) {
      font-size: 1.8rem !important;
      padding: 1.4vw 4vw;
    }

    @media (max-width: 1757px) {
      font-size: 2.5rem;
    }

    @media (max-width: 575px) {
      padding: 0.5rem 1.2rem !important;
      font-size: 1.7rem !important;
    }

    @media (max-width: 501px) {
      padding: 0.5rem 1.2rem !important;
    }

    @media (max-width: 338px) {
      padding: 0.5rem 1.2rem !important;
      font-size: 1.6rem !important;
    }
  }

  // AR banner
  .tu-ar-banner {
    @media (min-width: 320px) and (max-width: 767.98px) {
      width: 84vw !important;
      margin-left: auto;
      display: flex;
      transform: translate(-24%, -50%);
      flex-direction: column;
      align-items: center;
    }

    @media (min-width: 320px) and (max-width: 767.98px) {
      .ar-cta {
        margin-right: 0;
        padding-right: 0;

        a {
          font-size: 10px;
          padding: 7px;
        }
      }
    }

    .ar-title {
      @media (min-width: 320px) and (max-width: 767.98px) {
        font-size: 5vw !important;
      }
    }
  }

  @media (max-width: 575.98px) {
    .bn-text-center {
      transform: translate(-50%, -45%);
    }

    .title-main {
      font-size: 3.5vw !important;
      line-height: 6vw !important;
    }
  }

  .winner-contest {
    .list {
      &-btns {
        font-size: 2rem !important;
        padding: 1.6rem 3rem;

        @media (max-width: 1200px) {
          font-size: 1.3rem !important;
          padding: 1rem 2.4rem;
        }

        @media (max-width: 435px) {
          font-size: 1rem !important;
          padding: 0.7rem 2rem;
        }
      }
    }
  }

  .no_ball {
    .modal-bg {
      .modal-body {
        h4 {
          font-size: 28px;

          @media (max-width: 575px) {
            font-size: 21px;
          }

          @media (max-width: 386px) {
            font-size: 16px;
          }
        }
      }
    }
  }
  .section-sub-title{
    @media (max-width:767px) {
      font-size: 5vw;
    }
  }

  .btn-sm-ticket,.card-block-how-to-win-merch{
    height: 52px !important;
    display: flex;
    justify-content: center;
    align-items: center;
     padding: 6px !important;
     margin: 0 5px;
     min-height: unset;
  }
  @media (max-width:767.98px) {
     .btn-sm-ticket,.card-block-how-to-win-merch{
   height: 46px !important;
     font-size: 3vw !important;
   
  }
  }
 
}