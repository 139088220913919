//profile
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 0px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 0px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #333;
}

.tu-user-input:disabled {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  font-size: 22px;
  line-height: 23px;
  color: #ffffff;
  font-weight: 700;
  width: calc(100% - 20px);
  padding-left: 0;
  display: block;
}

.tu-profile-dropdown {
  .dropdown-menu {
    background: $secondary;
    min-width: 337px;
    width: 100%;
    height: 88vh;
    padding: 22px 0 0 0;
    border-radius: 15px;

    @media (max-height: 1000px) {
      overflow-y: auto;
      // padding-bottom: 100px;
      height: 71vh;
      overscroll-behavior: contain;
    }
  }

  .dropdown-toggle {
    &:after {
      display: none;
    }

    .thumbnail-image {
      position: relative;
      z-index: 2;
    }
  }

  ul.tu-user-details {
    list-style: none;
    display: block;
    padding: 0 22px;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .avtar-select {
        position: absolute;
        top: 5rem;
        left: 5.5rem;
        border: 1px solid $light;
        border-radius: 50%;
        width: 20px;
        background: #cecece;
        height: 20px;
        padding: 0px;

        img {
          width: 6px;
          height: 6px;
        }
      }

      img.thumbnail-image {
        @media only screen and (max-width:767px) {
          width: 50px !important;
          height: 50px !important;
        }
      }

      span {
        font-family: Thums Up;
        font-size: 22px;
        font-weight: 700;
        letter-spacing: 0px;
        color: #002169;
        text-align: left;
        white-space: nowrap;
        min-width: 75px;
      }

      .tu-input-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .error_message {
          font-size: 16px;
          color: #fff;
          // padding-left: 10px;
        }

        .select_message {
          padding-left: 5px;
        }

        .select-wrapper {
          flex: 1;
          margin-right: 1rem;
          margin-left: 6px;
          position: relative;

          &::after {
            content: "";
            border-bottom: 1px solid #fff;
            width: 141px;
            height: 1px;
            display: block;
            top: 26px;
            position: absolute;
            left: 4px;
          }
        }

        .drop-select{
          &::after {
            position: absolute;
            content: "";
           width: 9px;
           height: 9px;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url('~/public/assets/images/white-dropdown.png');
            top: 14px;
            right: 2rem;
            color : white;
          }
        }

        select {
          background: transparent;
          font-size: 1.5rem;
          color: #fff;
          font-weight: 600;
          width: calc(100% - 20px);
          border: 0;
          border-radius: 0;
          padding: 0 10px 0 0;
          height: 32px;
          line-height: 21px;
          appearance: none;
          position: relative;
          z-index: 10;

          &:focus {
            outline: 0;
            box-shadow: none;
            border-color: inherit;
          }

          option {
            font-size: 1.8rem;
            background-color: #009edb;
            color: #fff;
          }
        }
      }

      input.tu-user-input {
        background: transparent;
        border: none;
        font-size: 22px;
        line-height: 23px;
        color: $light;
        font-weight: 700;
        width: calc(100% - 20px);
        padding-left: 0;
        display: block;
        //text-transform: capitalize;

        &:disabled {
          padding-left: 10px;
          opacity: 1;
          -webkit-text-fill-color: $light;
        }

        &.edit {
          border-bottom: 1px solid $light;
          border-radius: 0;
          // margin-left: 10px;
          width: 100%;
          max-width: calc(100% - 40px);
        }

        &:focus-visible {
          outline: none;
        }

        &::placeholder {
          color: $light;
          font-weight: 700;
        }
      }

      button {
        background: transparent;
        border: none;
      }

      .tu-save-btn {
        color: $primary;
        background-color: $light;
        border-color: $light;
        width: 28px;
        font-size: 17px;
        border-radius: 4px;
        height: 25px;
        line-height: 10px;
        position: relative;

        svg{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      }
    }
  }
}

.tu-coins-section,
.tu-referrals-section {
  // border-top: 1px solid rgb(0 32 105 / 40%);
  background-image: url("~/public/assets/images/line.png");
  padding: 10px 22px;
  content: "";
  background-repeat: no-repeat;

  .tu-content-row {
    display: flex;
    justify-content: space-between;

    .tu-content {
      display: flex;
      justify-content: space-between;
      width: 65%;

      p {
        font-size: 22px;
        line-height: 28px;
        color: $light;
        font-weight: 700;
        margin-bottom: 3px;
        text-align: left;
        min-width: 25px;
      }
    }

    .btn {
      width: 79px;
      height: 27px;
      font-size: 1.2rem;
      line-height: 22px;
      padding: 3px 0;
      border-radius: 4px;
    }
  }
}

.tu-referrals-section {
  padding-top: 24px;
}

.tu-profile-dropdown.show:after {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  background: rgba(51, 77, 135, 0.7);
  // background-image: url('~/public/assets/images/line.png');
  z-index: 1;
}

//tooltip profile menu start
.avatar-dropdown img {
  max-width: 50px;
  height: 50px;
}

.tooltip {
  &-profile-avatar {
    &.show {
      opacity: 1;
    }

    .arrow {
      &::before {
        border-width: 0.8rem 0.8rem 0;
        border-top-color: #fff;
        left: -101px;
        top: 24px;
      }
    }

    .tooltip {
      &-inner {
        background-color: transparent;
        position: absolute;
        left: -100px;
        top: 25px;
      }
    }

    .avatar-options {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 200px;
      background-color: #fff;
      padding: 15px;
      border-radius: 10px;

      img {
        width: 40px;
        display: flex;
        cursor: pointer;
      }
    }
  }
}

body:has(.tu-profile-dropdown.show.dropdown) {
  overflow: hidden;
}

@media (max-width: 420px) {
  // .tu-profile-dropdown {
  //     span, input.tu-user-input, .small, p {
  //       font-size: 18px !important;
  //     }
     
  // }

  .tu-profile-dropdown .dropdown-menu{
    height: max-content;
  }
  .tu-referrals-section {
    padding-top: 4px;
}
}