.btn {
  &-light {
    color: $primary !important;
  }
  &-sm {
    font-weight: bold;
  }
  &-xs {
    font-size: 1.6rem;
    line-height: 1.6rem;
    font-weight: normal;
    padding: 0.6rem 1.2rem;
    border-radius: 0.4rem;
  }
  &-secondary{
    background-color: transparent;
    border-color: transparent;
    &:hover,&:active, &:focus{
      background-color: transparent !important;
      border-color: transparent !important;
    }
  }
}

@media (max-width: 575.98px) {
  .btn {
    font-size: 2.4rem;
    border-radius: 0.8rem;
    &-sm {
      font-size: 1.6rem;
      border-radius: 0.6rem;
    }
    &-xs {
      font-size: 1.4rem;
      line-height: 1.4rem;
    }
  }
}

