.tu-country-select {
    li {
        position: relative;
        background-repeat: no-repeat;

        &:hover {
            background-color: #d5d5d5 !important;
        }

        .ReactFlagsSelect-module_selectOptionValue__vS99- {
            align-items: baseline;

            span.ReactFlagsSelect-module_selectFlag__2q5gC {
                position: absolute;

                svg {
                    display: none;
                }
            }

            .ReactFlagsSelect-module_label__27pw9 {
                padding-left: 40px;
            }
        }
    }

    button#rfs-btn {
        span.ReactFlagsSelect-module_selectFlag__2q5gC {
            position: absolute;

            svg {
                display: none;
            }
        }

        span.ReactFlagsSelect-module_label__27pw9 {
            padding-left: 40px;
        }
    }
}

#rfs-AF,
.selected_country_AF {
    span.ReactFlagsSelect-module_selectFlag__2q5gC::after {

        background: url('~/public/assets/images/flags/afghanistan.png') left top no-repeat;
        content: "";
        width: 29px;
        height: 19px;
        display: block;
        background-size: 30px;
    }
}

#rfs-AU,
.selected_country_AU {
    span.ReactFlagsSelect-module_selectFlag__2q5gC::after {
        background: url('~/public/assets/images/flags/australia.png') left top no-repeat;
        content: "";
        width: 29px;
        height: 19px;
        display: block;
        background-size: 30px;
    }
}

#rfs-BD,
.selected_country_BD {
    span.ReactFlagsSelect-module_selectFlag__2q5gC::after {
        background: url('~/public/assets/images/flags/bangladesh.png') left top no-repeat;
        content: "";
        width: 29px;
        height: 19px;
        display: block;
        background-size: 30px;
    }
}

#rfs-GB,
.selected_country_GB {
    span.ReactFlagsSelect-module_selectFlag__2q5gC::after {
        background: url('~/public/assets/images/flags/england.png') left top no-repeat;
        content: "";
        width: 29px;
        height: 19px;
        display: block;
        background-size: 30px;
    }
}

#rfs-IN,
.selected_country_IN {
    span.ReactFlagsSelect-module_selectFlag__2q5gC::after {
        background: url('~/public/assets/images/flags/india.png') left top no-repeat;
        content: "";
        width: 29px;
        height: 19px;
        display: block;
        background-size: 30px;
    }
}

#rfs-NL,
.selected_country_NL {
    span.ReactFlagsSelect-module_selectFlag__2q5gC::after {
        background: url('~/public/assets/images/flags/netherlands.png') left top no-repeat;
        content: "";
        width: 29px;
        height: 19px;
        display: block;
        background-size: 30px;
    }
}

#rfs-NZ,
.selected_country_NZ {
    span.ReactFlagsSelect-module_selectFlag__2q5gC::after {
        background: url('~/public/assets/images/flags/newzeland.png') left top no-repeat;
        content: "";
        width: 29px;
        height: 19px;
        display: block;
        background-size: 30px;
    }
}

#rfs-PK,
.selected_country_PK {
    span.ReactFlagsSelect-module_selectFlag__2q5gC::after {
        background: url('~/public/assets/images/flags/pakistan.png') left top no-repeat;
        content: "";
        width: 29px;
        height: 19px;
        display: block;
        background-size: 30px;
    }
}

#rfs-ZA,
.selected_country_ZA {
    span.ReactFlagsSelect-module_selectFlag__2q5gC::after {
        background: url('~/public/assets/images/flags/southafrica.png') left top no-repeat;
        content: "";
        width: 29px;
        height: 19px;
        display: block;
        background-size: 30px;
    }
}

#rfs-LK,
.selected_country_LK {
    span.ReactFlagsSelect-module_selectFlag__2q5gC::after {
        background: url('~/public/assets/images/flags/srilanka.png') left top no-repeat;
        content: "";
        width: 29px;
        height: 19px;
        display: block;
        background-size: 30px;
    }
}