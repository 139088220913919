.carousel-inner {
  display: flex;
}
.banner {
  .video-player {
    width: 100% !important;
    height: 100% !important;
    // position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;

    & > div {
      width: 100% !important;
      height: 100% !important;
      // width: 100%;
      // position: relative;
      // height: 0px !important;
      // padding-bottom: 20.9%;
      // @media (max-width:991px) {
      //   padding-bottom: 38.2%;
      // }
      // @media (max-width:767px) {
      //   padding-bottom: 85.9%;
      // }
      // @media (max-width:500px) {
      //   padding-bottom: 70%;
      // }
      // @media (max-width:425.9px) {
      //   padding-bottom: 82.3%;
      // }
      // @media (max-width:393px) {
      //   padding-bottom: 85.9%;
      // }
      // @media (max-width:375px) {
      //   padding-bottom: 85.9%;
      // }
      // @media (max-width:320px) {
      //   padding-bottom: 78.1%;
      // }
      // iframe {
      //   position: absolute;
      //   height: 100%;
      //   width: 100%;
      //   left: 0;
      //   top: 0;
      //   .ytp-embed .ytp-chrome-controls{
      //     position: relative;
      //     z-index: -1;
      //   }
      // }
    }
  }

  .carousel-item {
    overflow: hidden;
  }
&-wrapper{
  max-width: 29vw;
 
  width: 100%;
  h3{
    font-size: 2.31vw;
  }
  .take-picture {
    padding: 18px 72px;
    font-size: 32px;
    font-weight: 400;
  }
}
  &-content {
    max-width: 47vw;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: 0 auto;

    &-hotstar {
      max-width: 100% !important;
    }

    &-body {
      max-width: 37vw;
      &-left {
        float: left;
        margin-left: 12%;
      }
      &-right {
        float: right;
        margin-right: 12%;
      }
    }

    &-img {
      max-width: 194px;
      width: 12vw;
      transition: width 0.3s ease;
      margin-right: 0.5rem;
    }
  }

  &-img {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;

    @media (max-width: 1440px) {
      // width: unset;
      // max-width: unset;
    }

    @media (max-width: 991.98px) {
      width: 100%;
      max-width: 100%;
      height: 101%;
      object-fit: cover;
    }
  }

  .scan {
    padding-left: 20vw;

    @media (max-width: 767.98px) {
      padding-left: 38vw;
    }

    @media (max-width: 500px) {
      padding-left: 47vw;

      h3 {
        font-weight: bold;
      }
    }

    &-head {
      padding-left: 7vw;
    }

    p {
      font-size: 1.5vw;
      background-color: #1664c1;
      margin: 0 auto;
      display: inline-block;
      margin-left: 7vw;
      padding: 0.3rem 1.6rem;

      @media (max-width: 575.98px) {
        font-size: 2.7vw;
        line-height: 3.5vw;
        padding: 0.7rem 1rem;
      }
    }

    .section {
      &-title {
        font-size: 6vw;
        line-height: 6vw;
        font-weight: bold;

        @media (max-width: 575.98px) {
          font-size: 8vw;
          line-height: 7vw;
        }
      }
    }
  }

  .section {
    &-sub-title {
      // font-size: 2.8rem;
      font-size: 2.6rem;
      line-height: 1.2;
      color: #009edb;

      .text-large {
        font-size: 4.4rem;
      }
    }

    &-title {
      font-size: 3.2vw;

      &-main-heroContest {
        margin-left: -11rem;
      }

      &-hotstar {
        font-size: 9rem !important;
        line-height: 0.82 !important;
        letter-spacing: 1px !important;
        &-left {
          span {
            text-align: center !important;
          }
          color: #009fdc !important;
          &-upperSpan {
            color: #009fdc !important;
            font-size: calc(100% / 0.885) !important;
          }
          &-middleSpan {
            color: #009fdc !important;
          }
          &-lowerSpan {
            color: #009fdc !important;
          }
          &-quesSpan {
            color: #ce0e2d !important;
          }
        }

        &-right {
          span {
            text-align: center;
            color: white;
          }
          &-upperSpan {
            font-size: calc(100% / 1.2);
          }
          &-middleSpan {
            font-size: calc(100% / 0.98);
          }
          &-lowerSpan {
            font-size: calc(100% / 0.85);
          }
        }
      }
    }
  }
}

.carousel-control-prev,
.carousel-control-next {
  display: none;
}

ol.carousel-indicators {
  display: inline-flex;
  z-index: 3 !important;
  left: 50%;
  right: auto;
  margin: 0 0 1rem 0;
  transform: translateX(-50%);

  li.active {
    background-color: $light;
  }

  li {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: transparent;
    border: 2px solid $light;
    margin: 0 5px;
  }
}

@media (min-width: 1200px) and (max-width: 1480px) {
  .banner {
    .section {
      &-title {
        &-hotstar {
          font-size: 7.6rem !important;
          line-height: 0.82 !important;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .banner {
    .section {
      &-title {
        font-size: 4rem;
        line-height: 1.1;
        margin-left: -2rem;
        padding: 0 2rem;

        &-main-heroContest {
          margin-left: 8rem !important;
          padding: 0rem 1rem;
          line-height: 1;
          font-size: 4rem;
        }

        &-hotstar {
          font-size: 6rem !important;
          line-height: 0.82 !important;
        }
      }

      &-sub-title {
        margin-top: -0.5rem;
        font-size: 2vw;
        margin-left: -2.5rem;

        &-heroContest {
          margin-left: -1rem;
        }

        .text-large {
          font-size: 2vw;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 929px) {
  .banner {
    .section {
      &-title {
        font-size: 3.5rem;
        line-height: 1.1;
        margin-left: -2.5rem;
        padding: 0 1rem;

        &-hotstar {
          line-height: 0.82 !important;
          font-size: 7rem !important;
        }
      }

      &-sub-title {

        &-heroContest {
          font-size: 2.5vw;
          line-height: 1;
          margin-left: 0rem;
        }
        
        margin-top: -0.5rem;
        font-size: 2vw;

        .text-large {
          font-size: 2vw;
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  .banner {
    .btn {
      &-primary {
        font-size: 2.4vw;
      }
    }

    .section {
      &-title{
        &-main-heroContest {
          font-size: 3rem;
          line-height: 1;
          margin-left: 6.5rem;
          padding: 0 1rem;
        }
      }

      &-sub-title{
        &-heroContest {
          font-size: 2.5vw;
          line-height: 1;
          margin-left: 0rem;
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .banner {
    &-content {
      justify-content: start;
      max-width: 86%;

      &-img {
        width: 18vw;
      }

      &-body {
        // max-width: 35vw;
        max-width: 50vw;
      }
    }

    .section {
      &-title {
        font-size: 6.4vw;
        margin-bottom: 0.5rem;

        &-main-heroContest {
          margin-left: -1rem;
          padding: 0rem 0rem;
        }

        &-hotstar {
          font-size: 10vw !important;
          margin-bottom: 23vh !important;
        }
      }

      &-sub-title {
        font-size: 3vw;

        .text-large {
          font-size: 3.5vw;
        }
      }
    }

    .btn {
      &-primary {
        font-size: 4vw;
        padding: 1.5vw 5.5vw;
      }
    }
  }
}

@media (max-width: 600px) {
  .banner {
    &-content {
      &-img {
        width: 15vw;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .banner {
    &-coin {
      max-width: 12rem;
      width: 28vw;
    }

    .section {
      &-title {
        font-size: 6.4vw;
        line-height: 6.7vw;

        &-main-heroContest {
          font-size: 5.1vw;
          line-height: 5.7vw;
          margin-left: -1rem;
          //padding: 0rem 6rem;
          margin-bottom: -5px !important;
        }

        &-hotstar {
          font-size: 7vh !important;
          line-height: 0.82 !important;
          margin-bottom: 21vh !important;
        }
      }

      &-sub-title {
        font-size: 3.5vw;
        line-height: 3.7vw;

        .text-large {
          font-size: 4vw;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .banner {
    &-content {
      &-body {
        &-left {
          margin-left: 2%;
        }
        &-right {
          margin-right: 2%;
        }
      }
    }
    .section {
      &-title {
        &-hotstar {
          margin-bottom: -2vh !important;
          font-size: 4vh !important;
        }
      }
    }
  }
}

@media (max-width: 430px) {
  .banner {
    &-content {
      float: left;
      margin-top: -40px;

      &-img {
        width: 23vw;
      }
    }

    &-collect {
      margin-bottom: 1rem !important;
    }
  }

  img.steps-m {
    padding: 2rem 0;
  }
}

@media (max-width: 350px) {
  .banner {
    &-content {
      &-body {
        &-left {
          margin-left: -2%;
        }
        &-right {
          margin-right: -2%;
        }
      }
    }
  }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 20px;
  height: 30px;
}

.carousel-control-prev,
.carousel-control-next {
  opacity: 1;
}

.carousel-control-next-icon {
  background-image: url("~/public/assets/images/next-icon.png");
}

.carousel-control-prev-icon {
  background-image: url("~/public/assets/images/prev-icon.png");
}

.btn-share {
  position: absolute;
  padding: 9px;
  border-radius: 9px 0 0 9px;
  right: 0;
  top: 130px;
  z-index: 4;
  width: 46px;

  @media only screen and (max-width: 575px) {
    width: 30px;
    height: 30px;
  }

  img {
    width: 24px;

    @media only screen and (max-width: 575px) {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.btn-ar {
  position: absolute;
  padding: 9px;
  border-radius: 9px 0 0 9px;
  right: 0;
  top: 200px;
  z-index: 4;
  width: 46px;

  @media only screen and (max-width: 575px) {
    width: 30px;
    height: 30px;
    top: 180px;
  }

  img {
    width: 24px;

    @media only screen and (max-width: 575px) {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.banner-collect {
  padding: 16px 0 0;
  margin-bottom: 29px;

  @media (max-width: 767.67px) {
    padding: 0 !important;
  }

  @media only screen and (max-width: 325px) {
    margin-bottom: 0px !important;
  }
}

.ar-cta {
  @media (max-width: 767px) {
    // padding-right: 0;;
    padding-right: 4.5vw;
  }

  @media (max-width: 575px) {
    padding-right: 2vw;
  }

  @media (max-width: 500px) {
    margin-right: 2rem;
  }
}

@media only screen and (max-width: 500px) {
  .banner-img {
    max-height: 28rem !important;
    object-fit: cover;
    object-position: bottom;
    height: 100%;
  }

  .object {
    object-position: top;
  }

  .xy-center {
    transform: translate(-50%, -58%);
  }

  .banner {
    .btn {
      &-primary {
        line-height: 1.1;
        padding-bottom: 0.8rem !important;
        padding-top: 0.8rem !important;
      }
    }

    .card-block-how-to-win-merch {
      min-height: 44px;
      padding-bottom: 1.7rem !important;
      padding-top: 1.7rem !important;
    }
  }
}

@media only screen and (max-width: 325px) {
  .banner-img {
    max-height: 24rem !important;
  }

  .banner {
    .btn {
      &-primary {
        line-height: 1.1;
        padding-bottom: 0.8rem !important;
        padding-top: 0.8rem !important;
      }
    }

    .card-block-how-to-win-merch {
      min-height: 44px;
      padding-bottom: 1.5rem !important;
      padding-top: 1.5rem !important;
    }
  }
}

// .b-2{
//   display:none;
// }



  @media (max-width: 1090px) {
    .b-2 {
      display: none;
    }
  }

  @media (max-width: 700px) {
    .b-1 {
      width: 65%;
    }
  }

  @media (max-width: 990px) {
    .take-picture {
      font-size: x-large;
    }
  }


  @media (max-width: 977px) {
    .capture-bg {
      flex-direction: column;
      align-items: center;
    }
  }

  @media (max-width: 977px) {
    .b-1 {
      width: 225px;
    }
  }
  
  .unaffected-text {
    font-size: 32px !important;
    padding: 18px 72px !important;
    line-height:2.4rem !important;
  }

  @media (max-width: 940px) {
    .unaffected-text {
      font-size: 28px !important;
      padding: 18px 37px !important;
      line-height:2.4rem !important;
    }
  }

  @media (max-width: 640px) {
    .unaffected-text {
      font-size: 18px !important;
      padding: 10px 17px !important;
      line-height:2.4rem !important;
    }
  }

  @media (max-width: 360px) {
    .unaffected-text {
      font-size: 18px !important;
      padding: 10px 10px !important;
      line-height:2.4rem !important;
    }
  }

  // @media (min-width: 990px) {
  //   .bg-modi{
  //     height: 365px;
  //   }
  //   }